import React from 'react';
import api from '../api';
import Swipe from 'react-easy-swipe';
class Carousel extends React.Component {

  coordinatingSlides = () => {
    const { month, selectMonth, selectYear } = this.props;
    const allSlides = document.querySelectorAll('.calendar__date');

    month.forEach((monthWithoutID, i) => monthWithoutID.id = i);

    /* eslint-disable */
    const selectedMonth = month.find((monthFinder, index) => {
      const isSelected = monthFinder.value === selectMonth && monthFinder.year === selectYear;
      if (isSelected) {
        return monthFinder;
      }
    });
    /* eslint-enable */

    const ifFirstPos = (date) => date.id === 0;
    const ifLastPos = (date) => date.id === month.length - 1;
    const ifAnotherPos = (date) => !ifFirstPos(date) && !ifLastPos(date);
    const nodeStore = {
      DOM: {
        clear: function () {
          allSlides.forEach((slideWillClear) => {
            slideWillClear.classList.remove('disable-pos_right');
            slideWillClear.classList.remove('current');
            slideWillClear.classList.remove('disable-pos_left')
          });
        },
        moveToRight: function (element) {
          element.classList.add('disable-pos_right')
        },
        moveToCenter: function (element) {
          element.classList.add('current')
        },
        moveToLeft: function (element) {
          element.classList.add('disable-pos_left')
        }
      }
    }

    const findInDom = (domCollection, enteringID) => {

      let thisElement = {};

      domCollection.forEach((element) => {

        if (parseInt(element.dataset.id) === enteringID) {
          thisElement = element;
        }
      });
      return thisElement;
    }
    nodeStore.DOM.clear();
    const ifMonthMoreThenOne = month.length > 1;

    if (ifMonthMoreThenOne) {

      if (ifFirstPos(selectedMonth)) {

        const thisDOMElement = findInDom(allSlides, selectedMonth.id);
        const nextDOMElement = findInDom(allSlides, selectedMonth.id + 1);


        nodeStore.DOM.moveToRight(nextDOMElement);
        nodeStore.DOM.moveToCenter(thisDOMElement)

        return false;
      }
      else if (ifAnotherPos(selectedMonth)) {

        const thisDOMElement = findInDom(allSlides, selectedMonth.id);
        const prevDOMElement = findInDom(allSlides, selectedMonth.id - 1);
        const nextDOMElement = findInDom(allSlides, selectedMonth.id + 1);


        nodeStore.DOM.moveToRight(nextDOMElement);
        nodeStore.DOM.moveToCenter(thisDOMElement);
        nodeStore.DOM.moveToLeft(prevDOMElement);

        return false;
      }
      else if (ifLastPos(selectedMonth)) {
        const thisDOMElement = findInDom(allSlides, selectedMonth.id);
        const prevDOMElement = findInDom(allSlides, selectedMonth.id - 1);

        nodeStore.DOM.moveToCenter(thisDOMElement);
        nodeStore.DOM.moveToLeft(prevDOMElement);

      }
    }
    else {
      const thisDOMElement = findInDom(allSlides, selectedMonth.id);
      nodeStore.DOM.moveToCenter(thisDOMElement);
    }

  }
  componentDidMount() {
    this.coordinatingSlides();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectMonth !== this.props.selectMonth) {
      this.props.fetchDays();
      this.coordinatingSlides();
    }
  }
  changeSlide = (e, id, date) => {

    this.props.setRedux({
      userDateOrder: ''
    });

    this.props.setRedux({
      selectYear: date.year,
      selectMonth: date.value
    });

  }
  swipeLeft = () => {
    const { month, selectMonth, selectYear } = this.props;

    const allSlides = document.querySelectorAll('.calendar__date');

    const sliderLength = month.length;

    if (month.length > 1) {

      this.props.setRedux({
        userDateOrder: ''
      });

      month.forEach((monthWithoutID, i) => monthWithoutID.id = i);

      let nextID = 0;

      allSlides.forEach((slide, i) => {
        const currentMonth = parseInt(slide.dataset.month) === selectMonth && parseInt(slide.dataset.year) === selectYear;
        if (currentMonth) {
          nextID = i + 1;
        }
      });
      if (nextID === sliderLength) {
        return false;
      }
      allSlides.forEach((nextMonth, i) => {
        if (i === nextID) {
          this.props.setRedux({
            selectYear: parseInt(nextMonth.dataset.year),
            selectMonth: parseInt(nextMonth.dataset.month)
          });
        }
      });
    }
    return false;

  }
  swipeRight = () => {
    const { month, selectMonth, selectYear } = this.props;

    const allSlides = document.querySelectorAll('.calendar__date');

    if (month.length > 1) {

      this.props.setRedux({
        userDateOrder: ''
      });

      month.forEach((monthWithoutID, i) => monthWithoutID.id = i);

      let prevID = 0;

      allSlides.forEach((slide, i) => {
        const currentMonth = parseInt(slide.dataset.month) === selectMonth && parseInt(slide.dataset.year) === selectYear;
        if (currentMonth) {
          prevID = i - 1;
        }
      });
      if (prevID < 0) {
        return false;
      }
      allSlides.forEach((prevMonth, i) => {
        if (i === prevID) {
          this.props.setRedux({
            selectYear: parseInt(prevMonth.dataset.year),
            selectMonth: parseInt(prevMonth.dataset.month)
          });
        }
      });
    }
    return false;
  }
  render() {
    const { month } = this.props;
    return (
      <Swipe
        onSwipeLeft={this.swipeLeft}
        onSwipeRight={this.swipeRight}
        allowMouseEvents={true}
        tagName="div"
      >
        <div className="wrapper__slider">
          {month.map((slider, i) =>
            <div onClick={(e) => this.changeSlide(e, i, slider)} data-month={slider.value} data-year={slider.year} key={i} data-id={i} className="calendar__date active__month">
              {slider.month} {slider.year}
            </div>)}
        </div>
      </Swipe>
    )
  }
}
export default api.connect(Carousel);