import React from 'react';
import api from '../api';
import TableCeil from './tableCeil';
import SubError from '../common/subErrors';
import Carousel from '../components/carousel';
import { addWeeks, differenceInCalendarMonths, addMonths } from 'date-fns';
class CalendarComp extends React.Component {
  constructor(props) { 
    super(props)
    this.state = {
      currentMonth: new Date().getMonth() + 1,
      storeMonth: 0,
      arrayOfDays: [],
      isDefault: false,
      startSlide: 0,
      classIs: '',
      dateStartIndex: 0
    }
    this.slider = React.createRef();
    this.calendar = React.createRef();
  }
  _normalize = () => {
    if (this.props.userDateOrder || this.props.userDateOrder === 0) {
      const childrens = this.calendar.current.children;
      for (let group of childrens) {
        if (group.classList.contains('table__custom')) {
          let children = group.children[0];
          if (parseInt(children.innerText) === this.props.userDateOrder.day) {
            if (!children.classList.contains('td')) {
              children.classList.add('choosing');
            }
            else {
              children.classList.add('today');
            }
          }
          else {
            if (!children.classList.contains('td')) {
              children.classList.remove('choosing');
            }
            else {
              children.classList.remove('today');
            }
          }
        }
      }
    }
    else {
      if (this.calendar.current) {
        const childrens = this.calendar.current.children;
        for (let group of childrens) {
          if (group.classList.contains('table__custom')) {
            let children = group.children[0];
            children.classList.remove('choosing');
          }
        }
      }
    }
  }
  componentDidMount() {

    this._normalize()
  }
  componentDidUpdate(prevProps, prevState) {
    if (!this.props.upLoadDays) {
      this._normalize();
    }
    // if (prevProps.userDateOrder !== this.props.userDateOrder && this.props.userDateOrder) {
    //   const { userDateOrder } = this.props;
    //   api.fetchMasterTime({
    //     "p_id_master": this.props.profile[0].id,
    //     "edate": `${userDateOrder.year}-${userDateOrder.month + 1}-${userDateOrder.day}`,
    //     "wtime_start": userDateOrder.time_job.timeStart.replace(/\./, ':'),
    //     "wtime_finish": userDateOrder.time_job.timeBreak.replace(/\./, ':'),
    //   }).then(res => this.props.setRedux({ masterFreeTime: res }))
    // }
  }
  selectDay = (e, day, dayData) => {
    this.props.setRedux({
      userTimeOrder: ''
    });
    e.persist();
    if (!e.target.classList.contains('close__date')) {
      this.setState({
        isDefault: false
      });
      this.props.setRedux({
        blockingNext: false,
        userDateOrder: {
          day: day,
          month: this.props.selectMonth,
          year: this.props.selectYear,
          time_job: dayData.timeJob
        }
      });
    }
    else {
      this.setState({
        isDefault: {
          error: true,
          day: e.target.innerText
        }
      });
      this.props.setRedux({ userDateOrder: 0, blockingNext: false });
    }
  }
  renderTable = () => {
    const lastDayOfMonth = new Date(this.props.selectYear, this.props.selectMonth + 1, 0).getDate(),
      firstDayOfMonth = new Date(this.props.selectYear, this.props.selectMonth, 1).getDay(),
      dayOfWeeks = this.props.t('calendar__dayOfMonth', { returnObjects: true })
    // parsingTime job
    const parsingJobTime = api.parserJob(this.props.profile[0].time_job);
    const days = ['Sunday', 'Monday', 'Tueday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    //build calendar
    let emptyFields = [], daysThisMonth = [];

    if (firstDayOfMonth !== 0) {
      for (let i = 1; i < firstDayOfMonth; i++) {
        emptyFields.push({
          item: i,
          active: false,
          id: i
        });
      }
    }
    else {
      for (let i = 0; i < 6; i++) {
        emptyFields.push({
          item: i,
          active: false,
          id: i
        });
      }
    }
    for (let i = 1; i <= lastDayOfMonth; i++) {
      daysThisMonth.push({
        item: i,
        active: true,
        id: i,
        timeJob: parsingJobTime.find((time) => {
          let thisDay = days[new Date(this.props.selectYear, this.props.selectMonth, i).getDay()];
          if (time.days === thisDay) {
            return parsingJobTime;
          }
          return false;
        }),
        timeJobApi: this.props.availableMasterDays ? (this.props.availableMasterDays.find((time) => i === parseInt(time.edate))) : false
      });
    }
    emptyFields = emptyFields.concat(daysThisMonth);
    return (
      <div ref={this.calendar} className="table">
        {dayOfWeeks.map((dayOfWeek, i) => (<div key={i} className="table__head">{dayOfWeek}</div>))}
        {emptyFields.map((day, i) => (<TableCeil isApi={this.props.availableMasterDays.length ? this.props.availableMasterDays : false} selectDay={this.selectDay} calendar={this.calendar} key={i} day={day} />))}
      </div>
    )
  }
  _fetchDays = () => {
    this.props.setRedux({
      upLoadDays: true,
      availableMasterDays: []
    });
    api.fetchAvailableDays({
      id: this.props.profile[0].id,
      masterWork: api.parserTimeJob(this.props.profile[0].time_job),
      month: this.props.selectMonth + 1,
      year: this.props.selectYear
    }).then(res => {
      if (res) {
        res.forEach((day) => {
           /* eslint-disable */
          day.edate = day.edate.match(/(?:\-)(\d\d)/gm)[1].replace(/-/, '');
          /* eslint-enable */
        });
        this.props.setRedux({
          availableMasterDays: res,
          upLoadDays: false
        });
      }
    });
  }
  renderMonth = () => {
    let today = new Date();
    let endPoint = this.props.profile[0].fixed_date ? new Date(this.props.profile[0].fixed_date) : addWeeks(today, this.props.profile[0].max_date_booking || 1);

    let maxMonthForBooking = differenceInCalendarMonths(today, endPoint), listMonth = [];
    // todo fixit
    maxMonthForBooking < 0 ? maxMonthForBooking = -maxMonthForBooking : maxMonthForBooking = maxMonthForBooking;

    const month = this.props.t('calendar__month', { returnObjects: true });

    listMonth.push({
      month: month[today.getMonth()],
      value: today.getMonth(),
      year: today.getFullYear()
    });

    if (maxMonthForBooking) {
      for (let i = 1; i <= maxMonthForBooking; i++) {
        let itterationMonth = addMonths(today, i);
        listMonth.push({
          month: month[itterationMonth.getMonth()],
          value: itterationMonth.getMonth(),
          year: itterationMonth.getFullYear()
        });
      }
    }
    return listMonth;
  }
  render() {
    const {
      t,
      blockingNext,
      upLoadDays } = this.props;
    return (
      <div className="calendar__window">
        {!upLoadDays &&
          <>
            <header className="calendar__header">
              <div className="calendar__corusel">
                <Carousel fetchDays={this._fetchDays} month={this.renderMonth()} />
              </div>
            </header>
            <div className="wrapper_in_block ">
              {this.renderTable()}
            </div>
            <div className="error">
              {!blockingNext &&
                <SubError
                  expression={this.state.isDefault}
                  text={`${t('calendar__error', {
                    day: this.state.isDefault.day,
                    month: t('calendar__monthError', { returnObjects: true })[this.props.selectMonth]
                  })}`} />
              }
            </div>
          </>
        }
        {upLoadDays &&
          <div className="preloader transparent">
            <div className="fas fa-spinner fa-spin spin"></div>
          </div>
        }
      </div>
    )
  }
}
export default api.connect(CalendarComp);