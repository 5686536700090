import React from 'react';
import { withNamespaces } from "react-i18next";

const Policy = (props) => {
  const { t, onApplyTerms } = props;
  return (
    <div className="wrapper">
      <div className="wrapper page-useterms">
        <header className="header-useterms"> 
          <div className="wrapper_in_block">
            <div className="header__title">{t('policy__title')}</div>
          </div>
        </header>
        <section className="content">
          <div className="wrapper_in_block">
            {t('policy', { returnObjects: true }).map((rule, i) => (<div key={i} className="content__text">{rule}</div>))}
            <div className="content__full_rules">
              {t('policy__full', { returnObjects: true }).map((fullRule, i) => {
                return (
                  <div key={i + 'block'} className="terms__block">
                    <div key={i + 'header'} className="context__header">{fullRule.title}</div>
                    <div key={i} className="content__text">{fullRule.content.map((item, i) => (<p key={i}>{item}</p>))}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <header className="header-useterms second_policy_header">
          <div className="wrapper_in_block">
            <div className="header__title">{t('termOfUse__title')}</div>
          </div>
        </header>
        <section  className="content"> 
          <div className="wrapper_in_block">
            {t('termOfUse__rules', { returnObjects: true }).map((rule, i) => (<div key={i} className="content__text">{rule}</div>))}
            <div className="content__full_rules">
              {t('termOfUse__fullRules', { returnObjects: true }).map((fullRule, i) => {
                return (
                  <div key={i + 'block'} className="terms__block">
                    <div key={i + 'header'} className="context__header">{fullRule.title}</div>
                    <div key={i} className="content__text">{fullRule.content.map((item, i) => (<p key={i}>{item}</p>))}</div>
                  </div>
                )
              })}
            </div>
            <div className="content__footer">
              <div className="content__text">{t('termOfUse__footerRules')} <span><b>{t('termOfUse__footerEmail')}</b></span></div>
            </div>
          </div>
        </section>
        <div className="wrapper_in_bloc"><div className="footer__bt" onClick={onApplyTerms}>{t('termOfUse__btnAccept')}</div></div>
      </div>
    </div>
  )
}

const PolicyClass = withNamespaces()(Policy);
export default PolicyClass;
