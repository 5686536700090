import React from 'react';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import '../../scss/components/captcha/_captcha.scss'
const { CONFIG } = window;

const ReCaptchaButton = ({ onVerify, onError, t }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      onError()
      return;
    }

    const token = await executeRecaptcha();
    if (!token) {
      onError()
      return
    }
    onVerify(token, true)
  };

  return (
    <div className="captchav3">
      <div className="spinner">
        <label>
          <input type="checkbox" onChange={handleReCaptchaVerify} />
          <span className="checkmark"><span>&nbsp;</span></span>
        </label>
      </div>
      <div className="text">
        {t("captcha_text")}
      </div>
      <div className="logo">
        <img src="https://forum.nox.tv/core/index.php?media/9-recaptcha-png/"/>
        <p>reCAPTCHA</p>
        <small>Privacy - Terms</small>
      </div>
    </div>
  )
};

const GoogleReCaptchaComponent = ({ onVerify = () => { }, onError = () => { }, t }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={CONFIG.kaptcha}>
      <ReCaptchaButton {...{ onVerify, onError, t }} />
      <span className="textAfter">This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </span>
    </GoogleReCaptchaProvider>
  )
}

export default GoogleReCaptchaComponent;