import React from 'react';
import api from './api';
import ROUTES from './routes';
import COMPONENTS from './components';
import { Route, Switch } from "react-router-dom";
import ModalDelete from './components/modal';
import ErrorModal from './components/errorModal';
export class App extends React.Component {
  render() {
      return (
        <div className="page">
          {this.props.showError && <ErrorModal history={this.props.history} />}
          {this.props.showModal && <ModalDelete history={this.props.history} />}
          {this.props.isPreloaderActive && <COMPONENTS.Preloader />}
          <Switch>
            {ROUTES.map((route, key) => (<Route {...route} key={key} />))}
          </Switch>
        </div>
      );
    }
}

export default api.connect(App);