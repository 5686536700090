import React from 'react';
import PAGES from './pages';

export default [
  { exact: true, path: '/', render: () => (<PAGES.Exact />) },
  { exact: true, path: '/master/:id', render: (props) => (<PAGES.Welcome  {...props} />) },
  { exact: true, path: '/master/:id/terms', render: (props) => (<PAGES.Main  {...props} />) },
  { exact: true, path: '/master/:id/:step', render: (props) => (<PAGES.Usage location={props} {...props.match.params} />) },
  { exact: true, path: '/policy', render: (props) => (<PAGES.Policy  {...props.match.params} />) },

  { exact: true, path: '/en', render: () => (<PAGES.Exact />) },
  { exact: true, path: '/en/master/:id', render: (props) => (<PAGES.Welcome  {...props} />) },
  { exact: true, path: '/en/master/:id/terms', render: (props) => (<PAGES.Main  {...props} />) },
  { exact: true, path: '/en/master/:id/:step', render: (props) => (<PAGES.Usage location={props} {...props.match.params} />) },
  { exact: true, path: '/en/policy', render: (props) => (<PAGES.Policy  {...props.match.params} />) },
  
  { exact: false, render: (props) => (<PAGES.NotFound   {...props.match.params} />) },
]