import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import WelcomeHeader from '../components/welcome/welcomeHeader';

class Exact extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <WelcomeHeader t={t} />
        <section className="welcome-section">
          <div className="wrapper_in_block section__wrapper">
            <div className="section__title">
              <div>
                <div>{t('exact__title')}</div>
                {t('exact__title_next')}
              </div>
              <div className="section__info">
                {t('exact__desc')}
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
const ExactCls = withNamespaces()(Exact);
export default ExactCls;