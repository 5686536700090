import React from 'react';
import api from '../../api';

export class ServiceItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleClass: this.props.serviceStore.find((item) => item._id === this.props._id) ? true : false
    }
  }
  chooseService = (e) => {
    this.setState({
      toggleClass: !this.state.toggleClass
    }, () => {
      if (this.state.toggleClass) {
        let selected = { ...this.props.data };

        selected.isSelected = true;
        selected.price = api.renderPrice(this.props.price, this.props.data.currency);

        this.props.setRedux({
          serviceStore: [...this.props.serviceStore, selected],
          userDateOrder: '',
          blockingNext: false
        });
      }
      else {
        this.props.setRedux({
          serviceStore: [...this.props.serviceStore].filter(item => item._id !== this.props._id)
        });
      }
    });
  }
  render() {
    const { t } = this.props;
      return (
      <div onClick={this.chooseService} className="product">
        <div className="wrapper_in_block product_flex">
          <div className={api.setClasses(['product__decor'], { is_choosing: this.state.toggleClass })}></div>
          <div className="product__dashboard">
            <div className="dashboard__title">{this.props.title}</div>
            <div className="dashboard__marketing">
              <div className="marketing__time">
                {this.props.time.h !== 0 && <>{this.props.time.h} {api.wordType(this.props.time.h, [t('hoursIn_header')
                  , t('hoursIn_header_2'), t('hoursIn_header_3')])}</>} {this.props.time.min !== 0 && <>{this.props.time.min} {t('global__minutes')}</>}
              </div>
              <div className="marketing__price">
                <span></span>
                {api.renderPrice(this.props.price, this.props.data.currency)}
              </div>
            </div>
          </div>
          {this.state.toggleClass &&
            <span className={api.setClasses(['dashboard__checking'], { checking_active: this.state.toggleClass })}></span>
          }
        </div>
      </div>
    )
  }
}

export default api.connect(ServiceItem);

