import React from "react";
import api from "../../api";
import Preloader from "../preloader";
import HeaderMenager from "../headerMenager";
import TimePicker from "../timePicker";
import dayjs from 'dayjs';

import {
  set,
  getHours,
  getMinutes,
  subMinutes,
  isBefore,
  areIntervalsOverlapping,
  differenceInMinutes,
  isEqual,
  addMinutes,
} from "date-fns";

class TimeOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNext: true,
      isDefault: false,
      timeBild: false,
      isDayFull: false,
    };
    this.timeScale = React.createRef();
    this.interval = {};

    this.getDurationInMin = this.getDurationInMin.bind(this)
    this.getInterval = this.getInterval.bind(this);

    this.direction = {
      'DEFAULT': 0,
      'FROM_FINISH': 1,
      'TO_CENTER': 2
    }
  }

  handlingIntervals(intervals, userDuration) {
    let newIntervals = [];
    const onlyFullWorkMasterTime = intervals.every(interval => interval.ev_date === null);
    intervals = intervals.filter(interval => interval.ev_date !== 'break');
    if (!onlyFullWorkMasterTime) {
      intervals = intervals.filter(interval => {
        if (interval.ev_date !== null) {
          return interval;
        }

        return false;
      });
    }

    intervals.forEach(interval => {
      const { ev_date } = interval;
      const { end, start } = interval.interval;
      const intervalDurationInMin = Math.abs(differenceInMinutes(start, end));
      let newInterval = {};

      if (intervalDurationInMin && userDuration) {
        const deltaDuration = parseInt(intervalDurationInMin / userDuration);
        if (deltaDuration >= 1) {
          const step = userDuration;

          for (let i = 0; i <= intervalDurationInMin - step; i += step) {
            if (i === 0) {
              newInterval = {
                ev_date,
                interval: {
                  start,
                  end: addMinutes(start, step)
                }
              }
            }

            else {
              const lastNewInterval = newIntervals[newIntervals.length - 1];
              newInterval = {
                ev_date,
                interval: {
                  start: lastNewInterval.interval.end,
                  end: addMinutes(lastNewInterval.interval.end, step)
                }
              }
            }

            newIntervals.push(newInterval);
          }
        }

        else {
          newIntervals.push(interval);
        }
      }

    });

    return newIntervals;
  }

  componentDidMount() {
    // Make static
    const { userDateOrder, profile } = this.props;

    api
      .fetchMasterTime({
        p_id_master: this.props.profile[0].id,
        edate: `${userDateOrder.year}-${userDateOrder.month + 1}-${
          userDateOrder.day
          }`,
        wtime_start: userDateOrder.time_job.timeStart.replace(/\./, ":"),
        wtime_finish: userDateOrder.time_job.timeBreak.replace(/\./, ":"),
      })
      .then((res) => {
        if(res.length == 1) this.setState({ isDayFull: true })
        const userDuration =
          api.timeCalculate(this.props.serviceStore).h * 60 +
          api.timeCalculate(this.props.serviceStore).min;
        let array = this.prepareTime(
          res,
          userDuration,
          userDateOrder,
          profile[0]
        );
        this.props.setRedux({
          masterFreeTime: res,
        });
        this.setState(
          {
            timeBild: array,
          },
          () => {
            if (!this.timeScale.current) return
            this.timeScale.current.childNodes.forEach((child, i) => {
              if (child.dataset.date === this.props.userTimeOrder) {
                child.classList.add("active");
              }
            });

            const isToday =
              userDateOrder.year === new Date().getFullYear() &&
              userDateOrder.month === new Date().getMonth() &&
              userDateOrder.day === new Date().getDate();

            const updateTimeScale = () => {
              this.setState({
                timeBild: this.prepareTime(
                  res,
                  userDuration,
                  userDateOrder,
                  profile[0]
                ),
              });
            };
            if (isToday) {
              this.interval = setInterval(updateTimeScale, 1000);
            }
          }
        );
      });
  }
  componentDidUpdate(prevProps) {
    const { userDateOrder, masterFreeTime, profile } = this.props;

    const userDuration =
      api.timeCalculate(this.props.serviceStore).h * 60 +
      api.timeCalculate(this.props.serviceStore).min;

    if (
      prevProps.serviceDeleteTrigger !== this.props.serviceDeleteTrigger &&
      this.props.serviceDeleteTrigger
    ) {
      this.timeScale.current.childNodes.forEach((child, i) => {
        child.classList.remove("active");
      });

      this.setState({
        timeBild: this.prepareTime(
          masterFreeTime,
          userDuration,
          userDateOrder,
          profile[0]
        ),
        isDefault: false,
        isNext: true,
      });

      this.props.setRedux({
        serviceDeleteTrigger: false,
      });
    }
  }
  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  getDurationInMin = (time) => {
    return getHours(time) === 0 ? 1440 + getMinutes(time) : getHours(time) * 60 + getMinutes(time);
  }

  formatDate = (date, time) => {
    const timeCheck = time.split(':')[0] === '24';
    if (timeCheck) time = time.replace('24', '00');
    let createDate = new Date(`${date.year}/${date.month + 1}/${date.day} ${time}`);
    return timeCheck ? dayjs(createDate).add(1, 'day').$d : createDate;
  }

  createInterval = ({ ts, tf }, date) => {
    ts = this.formatDate(date, ts);
    tf = this.formatDate(date, tf);
    const startDayWork = this.getDurationInMin(ts);
    const finishDayWork = this.getDurationInMin(tf);

    const fullDay = {
      interval: {
        ts,
        tf,
        startMinute: startDayWork,
        finishMinute: finishDayWork
      }
    }

    let interval = []

    return {
      setInterval: ({ ts, tf, ev_date, duration }, step, date) => {
        if (duration === 0 || duration < step) return;

        if (ev_date === null) {
          ts = fullDay.interval.ts;
          tf = fullDay.interval.tf;
          ev_date = dayjs(fullDay.ts).format('YYYY-MM-DD')
          interval = [...interval, ...this.getInterval(ts, tf, startDayWork, finishDayWork, ev_date, step)];
          return;
        }

        ts = this.formatDate(date, ts);
        tf = this.formatDate(date, tf);

        const startInterval = this.getDurationInMin(ts, date);
        const finishInterval = this.getDurationInMin(tf, date);

        const enableStart = startDayWork === startInterval;
        const enableFinish = finishDayWork === finishInterval;

        let direction = this.direction.DEFAULT;

        if (!enableFinish) {
          if (enableStart) direction = this.direction.FROM_FINISH;
          else direction = this.direction.TO_CENTER;
        }

        interval = [...interval, ...this.getInterval(ts, tf, startInterval, finishInterval, ev_date, step, direction)]
      },

      getInterval: () => interval.sort((firstTime, secondTime) => firstTime.interval.startMinute - secondTime.interval.startMinute),
      getFullDay: () => fullDay
    }
  }

  getInterval(ts, tf, start, finish, ev_date, step, direction = 0) {
    let interval = [];
    let from_left = direction === this.direction.FROM_FINISH ? false : true;
    
    let duration = step;
    while (start < finish) {
      if (start + step > finish) {
        duration = finish - start;
      }

      if (from_left) {
        interval.push({
          ev_date,
          interval: {
            start: ts,
            end: addMinutes(ts, duration),
            startMinute: start,
            finishMinute: start + duration,
            duration
          }, 
          date: ts,
          active: duration >= step
        })
        
        if (direction !== this.direction.TO_CENTER || start + duration === finish) {
          start += duration;
          ts = addMinutes(ts, duration);
        }
      }

      else {
        interval.push({
          ev_date,
          interval: {
            start: addMinutes(tf, -duration),
            end: tf,
            startMinute: finish - duration,
            finishMinute: finish,
            duration
          },
          date: addMinutes(tf, -duration),
          active: duration >= step
        })
        tf = addMinutes(tf, -duration)
        finish -= duration
        if (direction === this.direction.TO_CENTER) {
          start += duration;
          ts = addMinutes(ts, duration);
        }
      }
      from_left = direction === this.direction.TO_CENTER ? !from_left : from_left;
    }
    return interval
  }


  prepareTime = (timeArray, step, date, user) => {

    const fullDayInterval = timeArray.find(interval => interval.ev_date === null);
    const createInterval = this.createInterval(fullDayInterval, date);

    if (this.state.isDayFull) {
      createInterval.setInterval(fullDayInterval, step, date)
    }
    else {
      timeArray.forEach(interval => {
        if (interval.ev_date === null) return;
        createInterval.setInterval(interval, step, date);
      });
    }

    let interval = createInterval.getInterval();
    const fullDay = createInterval.getFullDay();

    if (this.state.isDayFull) {
      interval.forEach(time => {
        if (time.interval.duration === step)
          time.show = true
      })

      return interval
    }

    interval.reduce((prev, current, currentIndex) => {
      if (currentIndex === 1 && prev.interval.startMinute !== fullDay.interval.startMinute) prev.show = true;

      if (currentIndex === interval.length - 1 && current.interval.finishMinute !== fullDay.interval.finishMinute) current.show = true;

      if (current.interval.startMinute - prev.interval.startMinute > step) {
        if (current.interval.duration >= step) current.show = true;
        if (current.interval.duration >= step) prev.show = true;
      }
      return current
    })

    return interval
  };

  selectTime = (e, data) => {
    if (data.active) {
      this.timeScale.current.childNodes.forEach((child, i) => {
        if (child.classList.contains("active")) {
          child.classList.remove("active");
        }
      });
      if (!e.target.classList.contains("active")) {
        this.props.setRedux({
          userTimeOrder: data.time,
          userTimeOrderDateFormat: e.target.dataset.format,
          timeClose: false,
        });

        this.setState({
          isDefault: false,
          isNext: false,
        });

        e.target.classList.add("active");
      }
    } else {
      this.setState({
        isDefault: true,
      });
      this.props.setRedux({
        timeClose: true,
      });
      this.props.setRedux({ blockingNext: false });
    }
  };
  render() {
    const {
      t,
      idUrl,
      step,
      userTimeOrder,
      history,
      userDateOrder,
      profile
    } = this.props;

    return (
      <>
        <HeaderMenager
          t={t}
          idUrl={idUrl}
          step={step}
          value={userTimeOrder}
          history={history}
          isNextComp={this.state.isNext}
          serviceTitle={t("timeOrder__title")}
          currentPos={3}
        />
        {this.state.timeBild == 0
        ? <></>
        :  <section className="section-usage">
            {this.state.timeBild.length && (
              <div className="services__time">
                <div className="wrapper_in_block">
                  <div ref={this.timeScale} className={`grid__time ${profile[0].hours_format_24 ? '' : 'ampm'}`}>
                    {this.state.timeBild.map((time, i) => (
                      <TimePicker
                        userDate={userDateOrder}
                        selectTime={this.selectTime}
                        key={i}
                        data={time}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            {!this.state.timeBild && <Preloader />}
          </section>
        }
      </>
    );
  }
}
export default api.connect(TimeOrder);
