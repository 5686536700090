import React from 'react';
import api, { getCookie } from '../api';
import Button from '../common/button';
import { Link } from 'react-router-dom';
import deleteIcon from '../../images/delete.png';
import Error from '../common/error';
import SubError from '../common/subErrors';
import backArrow from '../../images/Back_arrow.svg'

class HeaderMenager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActiveTab: false,
      isNext: true,
      modalFlag: false,
      value: this.props.value,
      btnTitle: this.props.step !== "phone" ? this.props.t('next_btn') : this.props.t('done_btn')
    }
    this.refHeader = React.createRef();
  }

  policeHeader = () => {
    this.setState({ modalFlag: true })
  }

  closeModal = () => {
    this.setState({ modalFlag: false })
  }

  toggleTab = () => {
    this.setState({
      isActiveTab: !this.state.isActiveTab
    });
  }
  componentDidUpdate() {
    if (!this.props.lastPage) {
      if (this.state.isActiveTab) {
        this.refHeader.current.classList.add('auto');
      }
      else {
        this.refHeader.current.classList.remove('auto');
      }
    }

    const { profile } = this.props;
    if (!getCookie('carabine')) {
      this.props.history.push(api.prepareUrl(`/master/${profile[0].id}`));
      this.props.setRedux({
        serviceStore: [],
        userHaveAuth: false,
        userOrderName: '',
        userOrderPhone: '',
        verifyCodeA: '',
        verifyCodeB: '',
        verifyCodeC: '',
        verifyCodeD: '',
        userTimeOrder: '',
        userDateOrder: '',
        userVerify: false
      });
    }
  }
  static getDerivedStateFromProps(nextProps) {
    if (nextProps.serviceStore.length === 1) {
      return { isActiveTab: false };
    }
    return null;
  }
  deleteService = (id) => {
    if (this.props.serviceStore.length > 1) {
      this.props.setRedux({
        serviceStore: [...this.props.serviceStore].filter(item => item._id !== id)
      });
      if (this.props.step === 'time') {
        this.props.setRedux({
          serviceDeleteTrigger: true,
          userTimeOrder: ''
        });
      }
      // else if (this.props.step === 'calendar') {
      //   this.props.setRedux({
      //     userDateOrder: ''
      //   });
      // }
    }
  }

  goBack = () => {
    window.history.back()
  }

  renderInfoUser = () => {
    return (
      <>
        <div className="headerbord__table_result">
          {this.props.userOrderName}
        </div>
        <div className="headerbord__table_result">
          {
            this.props.selectPhoneCode ?
              (
                <>
                  +{this.props.selectPhoneCode.code}
                </>
              )
              :
              (<>+7</>)
          }
          <span>{this.props.userOrderPhone}</span>
        </div>
      </>
    )
  }
  isIsrael = () => {
    const { selectPhoneCode } = this.props;
    let condition = false;
    if (typeof selectPhoneCode === 'object') {
      if (selectPhoneCode.id === 4) {
        condition = true
      }
    }
    else {
      condition = false;
    }
    return condition;
  }
  componentDidMount() {
    this.props.setRedux({ timeClose: false });
  }
  makeAuthUserIfWithoutMobileVerfiy() {
    const { 
      needMobileVerify, 
      userOrderName, 
      userOrderPhone, 
      profile, 
      userInstaName = '', 
      userSurnameName = '', 
      userHaveAuth 
    } = this.props;
    const requiredSurname = !profile[0].required_surname || profile[0].required_surname && !!userSurnameName;
    const requiredInsta = !profile[0].required_instagram || profile[0].required_instagram && !!userInstaName;

    if (!needMobileVerify && (userOrderPhone && userOrderName && requiredSurname && requiredInsta)) {
      this.props.setRedux({ userHaveAuth: true })
    }
    else if(userHaveAuth) {
      this.props.setRedux({ userHaveAuth: false })
    }
  }
  nextPage = (e) => {
    const { selectPhoneCode } = this.props;

    this.makeAuthUserIfWithoutMobileVerfiy();

    this.props.setRedux({ timeClose: false });
    const currentLength = selectPhoneCode ? api.maxLengthPhones.find((item) => item.code === selectPhoneCode.code).length : 14;
    if (api.checkError(this.props.step, [this.props.userDateOrder, this.props.userTimeOrder, this.props.userOrderPhone, this.props.userOrderName, this.props.userVerification, this.props.userInstaName], currentLength)) {
      this.setState({
        isNext: true
      });
      this.props.setRedux({ blockingNext: true });
      e.preventDefault();
    }

    else if (this.props.step === "phone") {
      if (this.props.profile[0].required_instagram && !Boolean(this.props.userInstaName)) {
        this.setState({
          isNext: true
        });
        this.props.setRedux({ blockingNext: true });
        e.preventDefault();
      }
      else if (this.props.profile[0].required_surname && !Boolean(this.props.userSurnameName)) {
        this.setState({
          isNext: true
        });
        this.props.setRedux({ blockingNext: true });
        e.preventDefault();
      }
      else {
        this.setState({
          isNext: false
        });
        this.props.setRedux({ blockingNext: false, editPhonePage: false });
      }
    }

    else if (!this.props.serviceStore.length || (this.props.editPhonePage && this.props.userOrderPhone.length < currentLength)) {
      this.setState({
        isNext: true
      });
      this.props.setRedux({ blockingNext: true });
      e.preventDefault();
    }
    else {
      this.setState({
        isNext: false
      });
      this.props.setRedux({ blockingNext: false, editPhonePage: false });
    }
  }

  getButtonStateOnVerify() {
    if (this.props.step === 'phone') {
      const surNameIsEmpty = this.props.profile[0].required_surname && !Boolean(this.props.userSurnameName)
      const instaIsEmpty = this.props.profile[0].required_instagram && !Boolean(this.props.userInstaName)
      return this.props.value && !surNameIsEmpty && !instaIsEmpty
    }
    return this.props.value
  }


  render() {
    let summTimer = 0;
    let summInterval;
    this.props.serviceStore.forEach(item => {
      summTimer = summTimer + 1 * item.duration.h * 60 + 1 * item.duration.min;
    })
    summInterval = summTimer / 15;
    this.props.setRedux({
      summInterval: summInterval
    });
    const { t, userTimeOrderDateFormat, serviceStore, selectPhoneCode, firstStep, currentPos, step } = this.props;
    const canDelete = !this.props.lastPage && this.props.step !== 'phone';
    const userDuration = api.timeCalculate(serviceStore).h * 60 + api.timeCalculate(serviceStore).min;
    const link = api.prepareUrl(`/master/${this.props.idUrl}/${api.getLink(this.props.step, this.props.editPhonePage, this.props.userHaveAuth, this.props.needMobileVerify)}`);
    const currentLength = selectPhoneCode ? api.maxLengthPhones.find((item) => item.code === selectPhoneCode.code).length : 14;
    const phoneValidator = this.props.userOrderPhone.length > currentLength - 1;
    const masterListStep = firstStep === 'list' ? 1 : 0;

    return (
      <header ref={this.refHeader} className={!this.props.lastPage ? "header header-usage" : "header header-usage auto"}>
        <div className={!this.props.lastPage ? "header__wrapper wrapper_in_block" : "header__wrapper wrapper_in_block last_page"}>
          <div className="headerbord">
            <div className="headerbord_wrapper">
              {(this.props.step === 'services' || this.props.step === 'list') &&
                <>
                  <div className="headerbord__service">{this.props.headerInfo.title}</div>
                  <div className="headerbord__user_name">{this.props.headerInfo.name}</div>
                  <div className="headerbord__state">{this.props.headerInfo.address}</div>
                </>
              }
            </div>
            {this.props.step !== 'calendar' && this.props.userDateOrder &&
              <div className="headerbord__table_result">
                <div className="headerbord__table_result_wrap">
                  <span>
                    {t('header_prepare_date', {
                      day: this.props.userDateOrder.day,
                      month: t('calendar__monthError', { returnObjects: true })[this.props.userDateOrder.month],
                      year: this.props.userDateOrder.year
                    })}
                  </span>
                  <span>
                    {this.props.step !== 'calendar' && this.props.step !== 'time' && this.props.userTimeOrder &&
                      <>{api._durationEvent(userTimeOrderDateFormat, userDuration, 'begin', this.props)} - {api._durationEvent(userTimeOrderDateFormat, userDuration, 'end', this.props)}</>
                    }
                  </span>
                </div>
              </div>
            }
            {!this.state.isActiveTab && currentPos !== 1 &&
              <>
                <div className="headerbord__table_result">
                  <p className="table_result__preview">{this.props.serviceStore[0].title_service}</p>
                  {this.props.serviceStore.length > 1 &&
                    <>
                      <span>+ {this.props.serviceStore.length - 1}</span>
                      {!this.props.lastPage &&
                        <div onClick={this.toggleTab} className="table_result__moreBtn">{t('showMoreBtn')}</div>
                      }
                      {this.props.editPhonePage &&
                        <div onClick={this.toggleTab} className="table_result__moreBtn">{t('showMoreBtn')}</div>
                      }
                    </>
                  }
                </div>
                <div className="headerbord__table_result">
                  <p>
                    {api.timeCalculate(this.props.serviceStore).h !== 0 && <>{api.timeCalculate(this.props.serviceStore).h} {
                      api.wordType(api.timeCalculate(this.props.serviceStore).h, [t('hoursIn_header')
                        , t('hoursIn_header_2'), t('hoursIn_header_3')])}</>}  {api.timeCalculate(this.props.serviceStore).min !== 0 && <>{api.timeCalculate(this.props.serviceStore).min} {t('global__uniqemin')}</>}
                  </p>
                  <p className="table_result__sum">
                    {api.renderPrice(api.sumCalculate(this.props.serviceStore, this.props.serviceStore[0].currency), this.props.serviceStore[0].currency)}
                  </p>
                </div>
                <div>
                  {this.props.step === 'phone' ?
                    <div>
                        <span className="escape__block">{t('termOfUse__Button__text')} <Link onClick={this.props.onTermsClick} className="termsOfUse__link">{t('termOfUse__Button__link')}</Link></span>
                        <span className="escape__block__invisible">{t('termOfUse__Button__text')} <Link onClick={this.props.onTermsClick} className="termsOfUse__link">{t('termOfUse__Button__link')} -&nbsp;-</Link></span>
                    </div>

                    : <></>}

                </div>
                {this.props.lastPage && !this.props.editPhonePage &&
                  this.renderInfoUser()
                }
              </>
            }
            {this.state.isActiveTab &&
              <div className="headerbord__active_services">
                <ul className="active_services__list">
                  {this.props.serviceStore.map((item, i) => {
                    if (i === 0) {
                      return (
                        <li key={i} className="item">
                          {canDelete &&
                            <div onClick={() => this.deleteService(item._id)} className="delete_item"><img src={deleteIcon} alt="delete item" /></div>
                          }
                          <div className="item__wrapper">
                            {item.title_service}
                          </div>
                          {!this.props.lastPage &&
                            <div onClick={this.toggleTab} className="table_result__moreBtn">
                              {t('hideBtn')}
                            </div>
                          }
                          {this.props.editPhonePage &&
                            <div onClick={this.toggleTab} className="table_result__moreBtn">
                              {t('hideBtn')}
                            </div>
                          }
                        </li>
                      );
                    }
                    else {
                      return (
                        <li key={i} className="item">
                          {canDelete &&
                            <div onClick={() => this.deleteService(item._id)} className="delete_item"><img src={deleteIcon} alt="delete item" /></div>
                          }
                          <div className="item__wrapper">
                            {item.title_service}
                          </div>
                        </li>
                      )
                    }
                  })}
                </ul>
                <div className="active_services__total">
                  <p className="active_services__result">
                    {api.timeCalculate(this.props.serviceStore).h !== 0 && <>{api.timeCalculate(this.props.serviceStore).h} {api.wordType(api.timeCalculate(this.props.serviceStore).h, [t('hoursIn_header')
                      , t('hoursIn_header_2'), t('hoursIn_header_3')])}</>}  {api.timeCalculate(this.props.serviceStore).min !== 0 && <>{api.timeCalculate(this.props.serviceStore).min} {t('global__uniqemin')}</>}
                  </p>
                  <p className="active_services__result">
                    {t('global__currency', { count: api.sumCalculate(this.props.serviceStore, this.props.serviceStore[0].currency) })}
                  </p>
                </div>
                {this.props.lastPage && !this.props.editPhonePage &&
                  this.renderInfoUser()
                }
              </div>
            }
          </div>

          <div className="wrapper_next_link">
            {!this.props.lastPage && this.props.step !== 'list' &&
              <Link onClick={this.nextPage} className="next_link" to={link}>
                <Button
                  className={api.setClasses(['next_button', 'done-btn'], { activeBtnNext: !this.props.editPhonePage ? this.getButtonStateOnVerify() : this.props.userOrderPhone.replace(/\D/gm, '').replace(/\s+/gm, '').length > 9 })}
                  title={!this.props.editPhonePage ? this.state.btnTitle : t('done_btn')} />
              </Link>
            }
          </div>
          {this.props.lastPage && !this.state.isActiveTab && !this.props.editPhonePage && serviceStore.length > 1 &&
            <div onClick={this.toggleTab} className="table_result__moreBtn show_all">{t('header__show__all')}</div>
          }
          {this.props.lastPage && this.state.isActiveTab && !this.props.editPhonePage &&
            <div onClick={this.toggleTab} className="table_result__moreBtn show_all">
              {t('hideBtn')}
            </div>
          }
          {this.props.lastPage && this.props.editPhonePage && this.props.step !== 'list' && <Link onClick={this.nextPage} className="next_link" to={link}>
            <Button
              className={api.setClasses(['next_button'], { activeBtnNext: !this.props.editPhonePage ? this.props.value : phoneValidator })}
              title={!this.props.editPhonePage ? this.state.btnTitle : t('done_btn')} />
          </Link>
          }
        </div>

        {!this.props.lastPage && !this.props.timeClose &&
          <Error
            t={t}
            isNext={this.props.isNextComp}
            paramsUrl={this.props.step}
            expression={this.props.blockingNext && this.props.isNextComp && !this.props.value}
          />
        }
        {!this.props.lastPage && this.props.timeClose && !this.props.blockingNext &&
          <SubError text={t('timeOrder__error')} expression={this.props.timeClose} />
        }
        {!this.props.editPhonePage &&
          <div className="services">
            <div className="wrapper_in_block">
              <div className="services__steps">
                {(this.props.firstStep !== 'list' && this.props.step !== "services") || (this.props.firstStep === 'list' && this.props.step !== "list") ?
                <div className="back__button-wrapper" >
                  <div
                    className="back__button"
                    onClick={() => this.goBack()}
                  >
                    <img className="back__button-symbol" src={backArrow} alt="<"/> <span>{t('go__back')}</span>
                </div>
                </div>
                : <></>}
                <p className="steps__counter">
                  {t('wizard__step')} {step !== 'list' ? currentPos + masterListStep : currentPos} {t('wizard__of')} {(this.props.needMobileVerify ? 5 : 4) + masterListStep}:
                  </p>
                <p className="steps__title">{this.props.serviceTitle}</p>
              </div>
            </div>
          </div>
        }
      </header >

    )
  }

}
export default api.connect(HeaderMenager);