import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import api, { getCookie } from '../api';
import MasterList from '../components/patterns/masterList';
import Services from '../components/patterns/services';
import DateOrder from '../components/patterns/dateOrder';
import TimeOrder from '../components/patterns/timeOrder';
import ContactsDetails from '../components/patterns/contactDetails';
import { Redirect } from 'react-router-dom';
import Verification from '../components/patterns/verification';
import Ready from './ready';
import createHistory from 'history/createBrowserHistory'


export class Usage extends Component {
  constructor(props) {
    super(props);
    if (this.props.step === 'services' && this.props.firstStep === 'list') {
      this.props.setRedux({ profile: [], serviceStore: [] })
    }
  }
  componentDidMount() {
    let isEng = false
    const locationUrl = window.location.href;
    window.scrollTo(0, 0);

    if (getCookie('carabine')) {
      if (!this.props.masterList.length) {
        this.setDependeces();
        this.getSaloonData();
      }
    }
    if (window.location.pathname.split('/').includes('en')) isEng = true
    
    if (this.iOS()) {
      if(isEng) {
        if (window.location.href.split('/').length < 7 ) return;
      } else {
        if (window.location.href.split('/').length < 6) return;
      }
        document.addEventListener('DOMContentLoaded', () => {
          if (!sessionStorage.getItem('startMasterUrl')) sessionStorage.setItem('startMasterUrl', window.location.href);
          if (sessionStorage.getItem('startMasterUrl') != window.location.href) {
            setTimeout(() => {
              window.location.href = sessionStorage.getItem('startMasterUrl')
            })
          }
        })
    } else {
      window.onbeforeunload = function() { 
        window.setTimeout(function () { 
          window.location.href = locationUrl;
        }, 0); 
        window.onbeforeunload = null; // necessary to prevent infinite loop, that kills your browser 
      }
    }
  }

  setDependeces = () => {
    this.props.setRedux({
      isPreloaderActive: true,
    });
    this.props.setRedux({
      userVerify: true,
      isConfirmTermOfuse: true
    });
  }

   iOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  getSaloonData = () => {
    api.fetchSaloonData(this.props.id).then((res) => {
      if (res.length) {
        this.props.setRedux({
          masterList: res,
        });
        setTimeout(() => {
          this.props.setRedux({
            isPreloaderActive: false,
          });
        })
        document.body.classList.remove('stop__scroll');
      } else {
        if (!this.props.profile.length) {
          this.getProfileData(true);
        } else {
          setTimeout(() => {
            this.props.setRedux({
              isPreloaderActive: false,
            });
          })
        }
      }
    });
  }

  getProfileData = async (isfirstStep) => {
    const resultData = await api.fetchData(this.props.id);
    if (resultData.length) {
      api.checkMasterDataIsFull(resultData, this.props.setRedux, this.props);
      this.props.setRedux({
        firstStep: isfirstStep ? 'services' : 'list'
        // firstStep: isfirstStep ? 'list' : 'services'
      });
      resultData.map((item, i) => {
        item['_id'] = i;
        item['duration'] = api.translateTime(item.duration);
        if (this.props.firstStep === 'list') {
          item['address'] = this.props.masterList[0].address;
        }
        return item;
      });
      this.props.setRedux({
        profile: resultData,
      });


      const result = await Promise.all([
        api.fetchAvailableDays({
          id: resultData[0].id,
          masterWork: api.parserTimeJob(resultData[0].time_job),
          month: this.props.selectMonth + 1,
          year: this.props.selectYear
        }),
        api.fetchLocation(),
        api.fetchCodePhone()
      ]);

      result[0].forEach((day) => {
        day.edate = day.edate.match(/(?:\-)(\d\d)/gm)[1].replace(/-/, '')
      });
      this.props.setRedux({ availableMasterDays: result[0] });

      if (result[1]) {
        this.props.setRedux({ userLocation: result[1] });
      }

      if (result[2]) {
        this.props.setRedux({ phoneCode: result[2] });
      }
      setTimeout(() => {
        this.props.setRedux({
          isPreloaderActive: false,
        });
      })
    } else {
      this.props.isEnglish ? this.props.location.history.push("/en/404") : this.props.location.history.push("/404");
    }
  }

  componentDidUpdate(props) {
    const {
      isPreloaderActive,
    } = this.props;

    if (isPreloaderActive) {
      return;
    }

    if (!getCookie('carabine')) {
      const history = createHistory();
      history.go(0)
    }
    if (getCookie('carabine')) {
      api.updSession({
        update: 'need update'
      });
    }
    if (!this.props.isConfirmTermOfuse) {
      this.props.location.history.push(api.prepareUrl(`/master/${this.props.id}/list`))   //terms
    }

    let oldPage = props.location.location.pathname.search('time') === -1;
    let curPage = this.props.location.location.pathname.search('time') !== -1;
    if (oldPage && curPage) {
      api.checkMasterDataIsFull(this.props.profile, this.props.setRedux, this.props);
    }
  }

  renderMasterList() {
    return (
      <div className="wrapper page-usage">
        <MasterList
          t={this.props.t}
          dataList={this.props.masterList}
          step={this.props.step}
          history={this.props.location.history}
          errorMessages={this.props.errorMessages}
        />
      </div>
    )
  }
  renderServices() {
    return (
      <div className="wrapper page-usage">
        <Services
          t={this.props.t}
          dataList={this.props.profile}
          idUrl={this.props.id}
          serviceStore={this.props.serviceStore}
          step={this.props.step}
          history={this.props.location.history}
          errorMessages={this.props.errorMessages}
        />
      </div>
    )
  }
  renderCalendar() {
    return (
      <div className="wrapper page-usage">
        <DateOrder
          t={this.props.t}
          serviceStore={this.props.serviceStore}
          idUrl={this.props.id}
          step={this.props.step}
          history={this.props.location.history}
          errorMessages={this.props.errorMessages}
        />
      </div>
    )
  }
  renderConfirmTime() {
    return (
      <div className="wrapper page-usage">
        <TimeOrder
          t={this.props.t}
          serviceStore={this.props.serviceStore}
          idUrl={this.props.id}
          step={this.props.step}
          history={this.props.location.history}
          errorMessages={this.props.errorMessages}
        />
      </div>
    )
  }
  renderContactsDetails() {
    return (
      <div className="wrapper page-usage">
        <ContactsDetails
          t={this.props.t}
          serviceStore={this.props.serviceStore}
          idUrl={this.props.id}
          step={this.props.step}
          history={this.props.location.history}
          errorMessages={this.props.errorMessages}
        />
      </div>
    )
  }
  renderVerification() {
    return (
      <div className="wrapper page-usage">
        <Verification
          serviceStore={this.props.serviceStore}
          idUrl={this.props.id}
          t={this.props.t}
          step={this.props.step}
          location={this.props.location}
          history={this.props.location.history}
          errorMessages={this.props.errorMessages}
        />
      </div>
    )
  }

  renderReady() {
    return (
      <>
        <Ready
          serviceStore={this.props.serviceStore}
          idUrl={this.props.id}
          step={this.props.step}
          location={this.props.location}
          history={this.props.location.history}
          errorMessages={this.props.errorMessages}
        />
      </>
    )
  }

  fresh = () => {
    if (this.props.readyActive && this.props.step !== 'ready') {
      this.props.setRedux({
        userHaveAuth: false,
        userDateOrder: '',
        masterFreeTime: {},
        userOrderName: '',
        userOrderPhone: '',
        userLocation: {},
        verifCodeRender: false,
        userVerification: false,
        timer: 60,
        saveServices: [],
        serviceStore: [],
        verifyPage: false,
        selectPhoneCode: '',
        phoneCode: {},
        verifyCodeA: '',
        verifyCodeB: '',
        verifyCodeC: '',
        verifyCodeD: '',
        againSend: false,
        helpView: false,
        editPhonePage: false,
        wasChangePhone: false,
      });
    }
  }

  navigator = () => {
    const {
      step,
      userVerify,
      isConfirmTermOfuse,
      serviceStore,
      userDateOrder,
      userTimeOrder,
      userHaveAuth,
      readyActive,
      firstStep,
      masterList,
      profile
    } = this.props;

    const renderRedirect = () => {
      let redirect;
      getCookie('carabine') ? redirect = api.prepareUrl(`/master/${this.props.id}/${firstStep}`) :
        redirect = api.prepareUrl(`/master/${this.props.id}`);

      return (
        <Redirect to={redirect} />
      )
    }
    if (this.props.step !== 'ready' && (this.props.showModal !== false || this.props.showError !== false)) {
      this.props.setRedux({
        showModal: false,
        showError: false
      });
    }
    if ((step === 'services' || step === 'list') && this.props.readyActive !== false) {
      this.props.setRedux({
        readyActive: false,
      });
    }

    const initialPath = [
      {
        name: 'list',
        dependeces: (firstStep === 'list') && userVerify && isConfirmTermOfuse && getCookie('carabine'),
        method: this.renderMasterList()
      },
      {
        name: 'services',
        dependeces: ((firstStep === 'list') && masterList.length && profile.length) || (firstStep === 'services' && userVerify && isConfirmTermOfuse && getCookie('carabine')),
        method: this.renderServices()
      },
      {
        name: 'calendar',
        dependeces: serviceStore.length && !readyActive,
        method: this.renderCalendar()
      },
      {
        name: 'time',
        dependeces: userDateOrder && !readyActive,
        method: this.renderConfirmTime()
      },
      {
        name: 'phone',
        dependeces: userTimeOrder && !readyActive,
        method: this.renderContactsDetails()
      },
      {
        name: 'check ',
        dependeces: !userHaveAuth ? true : false,
        method: this.renderVerification()
      },
      {
        name: 'ready',
        dependeces: userHaveAuth && userTimeOrder,
        method: this.renderReady()
      }
    ];
    /* eslint-disable */
    let render = initialPath.find((path) => {
    /* eslint-enable */
      if (path.name === step && path.dependeces) {
        return path.method;
      }
    });
    return render ? render.method : renderRedirect();
  }
  render() {
    return (
      <>
        {!this.props.isPreloaderActive ? this.navigator() : null}
      </>
    )
  }
}
const UsageCls = withNamespaces()(Usage);
export default api.connect(UsageCls);