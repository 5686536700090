import WelcomeHeader from "../components/welcome/welcomeHeader";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import Preload from "../components/preload";
import { lng } from '../multilang/translate';
const { CONFIG } = window;

const ReCaptchaV2 = ({ t, onVerify }) => {
    return (
        <>
            <Preload />
            <div className="preloaderFonts">
                <p className="bold__font">font loading...</p>
                <p className="regula__font">font loading...</p>
                <p className="thin__font">font loading...</p>
                <p className="light__font">font loading...</p>
                <p className="ghotic__font">font loading...</p>
                <p className="averin__font">font loading...</p>
            </div>
            <WelcomeHeader t={t} />
            <section className="welcome-section">
                <div className="wrapper_in_block section__wrapper">
                    <div className="captcha">
                        <ReCAPTCHA
                            type="image"
                            onChange={onVerify}
                            sitekey={CONFIG.kaptchaV2}
                            hl={lng}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

export default ReCaptchaV2;
