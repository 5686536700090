import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';

import api from '../api';
import Button from '../common/button';
import { Link } from 'react-router-dom';
import Preload from '../components/preload';
export class Main extends Component {
  confirmTerms = () => {
    this.props.setRedux({
      isConfirmTermOfuse: true
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.userVerify) {
      const goBack = api.prepareUrl(`/master/${this.props.match.params.id}`);
      this.props.history.push(goBack);
    }
    if (this.props.userVerify && this.props.isConfirmTermOfuse) {
      const goBack = api.prepareUrl(`/master/${this.props.match.params.id}/${this.props.firstStep}`);
      this.props.history.push(goBack);
    }
  }
  render() {
    const { t } = this.props;
    const link = api.prepareUrl(`/master/${this.props.match.params.id}/${this.props.firstStep}`);

    return (
      <div className="wrapper">
        <Preload />
        <div className="wrapper page-use">
          <header className="header-useterms">
            <div className="wrapper_in_block">
              <div className="header__title">{t('termOfUse__title')}</div>
            </div>
          </header>
          <section ref={this.refContent} className="content">
            <div className="wrapper_in_block">
              {t('termOfUse__rules', { returnObjects: true }).map((rule, i) => (<div key={i} className="content__text">{rule}</div>))}
              <div className="content__full_rules">
                {t('termOfUse__fullRules', { returnObjects: true }).map((fullRule, i) => {
                  return (
                    <div key={i + 'block'} className="terms__block">
                      <div key={i + 'header'} className="context__header">{fullRule.title}</div>
                      <div key={i} className="content__text">{fullRule.content.map((item, i) => (<p key={i}>{item}</p>))}</div>
                    </div>
                  )
                })}
              </div>
              <div className="content__footer">
                <div className="content__text">{t('termOfUse__footerRules')} <span><b>{t('termOfUse__footerEmail')}</b></span></div>
              </div>
            </div>
            <Link className="content__link" ref={this.refBtn} onClick={this.confirmTerms} to={link}>
              <Button title={t('termOfUse__btnAccept')} className="content__button" />
            </Link>
          </section>
        </div>
      </div>
    );
  }
}
const mainCls = withNamespaces()(Main)
export default api.connect(mainCls);