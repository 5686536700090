/* eslint-disable */
import { connect } from "react-redux";
import REDUX from "./redux";
import swal from "sweetalert";
import {
  set,
  getHours,
  getMinutes,
  format,
  subMinutes,
  isBefore,
  areIntervalsOverlapping,
  isEqual,
} from "date-fns";
import en from './multilang/en.json';
import ru from './multilang/ru.json';
const { CONFIG } = window;

export const Format = (time, props) => {
  return format(time, props.profile[0].hours_format_24 ? ru.translation.format_time : en.translation.format_time)
}

export function getCookie(name) {
 
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
  
}

const getMainServiceStore = (serviceStores) => {

  let find = serviceStores.find(({ time_interval }) => !time_interval)
  if (!find) find = serviceStores[0];
  return find
}

export const preparePrice = (summ, lang) => {
  let [summ0, summ1] = String(summ).replace(',', '').split('.');
  let result = `${summ0}`
  if (summ1 && summ1.length === 1) {
    summ1 = `${summ1}0`
  }
  if (lang === 'rub') {
    if (summ1 && summ1 !== '0' && summ1 !== '00') {
    }
  }
  else {
    if (!summ1) {
      summ1 = '00'
    }
    result = `${result}.${summ1}`
  }

  return result
}

export const setCookie = (name, value, options = { expires: 35000000 }) => {
  var expires = options.expires;

  if (typeof expires === "number" && expires) {
    var d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  var updatedCookie = name + "=" + value;

  for (var propName in options) {
    updatedCookie += "; " + propName;
    var propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += "=" + propValue;
    }
  }

  document.cookie = updatedCookie;
}

const IPTOPHONE = 'IPTOPHONE';
const API_URL = CONFIG.server;
const whithoutType = (state) => {
  let newState = {};
  Object.keys(state).forEach((key) => {
    if (key !== "type") {
      newState[key] = state[key];
    }
  });
  return newState;
};
const api = {
  format: Format,
  swal,
  prepareUrl: (url) => {
    const isEn = /\/en\//g.test(window.location.href);

    if (isEn) {
      url = `/en${url}`
    }

    return url;
  },

  setCookie,
  getCookie,
  fetchSaloonData: (id) => {
    return fetch(`${API_URL + "/web_list_master?id=eq." + id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": " application/json",
      },
    }).then((res) => res.json());
  },
  fetchData: (id) => {
    return fetch(`${API_URL + "/web_master_info?id=eq." + id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": " application/json",
      },
    }).then((res) => res.json());
  },
  fetchLocation: () => {
    return new Promise(resolve => {
      let result = { country: 'Russia', location: { country: 'RU' } };
      let _IPTOPHONE = getCookie(IPTOPHONE)
      if (!_IPTOPHONE) {
        fetch('https://ipapi.co/json/')
          .then(res => res.json())
          .then((res) => {
            if (res) {
              result.country = res.country_name;
              result.location = {
                status: 'success',
                country: res.country_code
              }
              setCookie(IPTOPHONE, JSON.stringify(result))
              resolve(result)
            }
            else {
              resolve(result)
            }
          })
      }
      else {
        resolve(JSON.parse(_IPTOPHONE))
      }
    })
  },
  fetchCodePhone: () => {
    return fetch(`${API_URL + "/phonecode"}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": " application/json",
      },
    }).then((res) => res.json());
  },
  fetchingToken: (keyCap, v3) => {
    const url = API_URL + (v3 ? '/captcha' : '/captchav2')
    return fetch(`${url}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": " application/json",
      },
      body: JSON.stringify({ token: keyCap }),
    }).then((res) => res.json());
  },
  fetchAvailableDays: (params) => {
    return fetch(`${API_URL + "/available_days"}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": " application/json",
      },
      body: JSON.stringify({ params: params }),
    }).then((res) => res.json());
  },
  fetchMasterTime: (params) => {
    return fetch(`${API_URL + "/master__time"}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": " application/json",
      },
      body: JSON.stringify(params),
    }).then((res) => res.json());
  },
  sendCode: (params) => {
    return fetch(`${API_URL + "/send_code"}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": " application/json",
      },
      body: JSON.stringify({ phone: params.phone }),
    }).then((res) => res.json());
  },
  saveEvent: (params) => {
    return fetch(`${API_URL + "/save"}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": " application/json",
      },
      body: JSON.stringify(params),
    }).then((res) => res.json());
  },
  saveServices: (params) => {
    return fetch(`${API_URL + "/saveService"}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": " application/json",
      },
      body: JSON.stringify(params),
    }).then((res) => res.json());
  },
  deleteEvent: (params) => {
    return fetch(`${API_URL + "/deleteEvent"}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": " application/json",
      },
      body: JSON.stringify(params),
    }).then((res) => res.json());
  },
  wordType: (n, text_forms) => {
    n = Math.abs(n) % 100;
    var n1 = n % 10;

    if (n > 10 && n < 20) {
      return text_forms[2];
    }

    if (n1 > 1 && n1 < 5) {
      return text_forms[1];
    }

    if (n1 == 1) {
      return text_forms[0];
    }

    return text_forms[2];
  },
  notifyMaster: (params) => {
    return fetch(`${API_URL + "/send_notify_to_master"}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": " application/json",
      },
      body: JSON.stringify(params),
    }).then((res) => res.json());
  },
  updSession: (msg) => {
    return fetch(`${API_URL + "/updtcarabine"}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": " application/json",
      },
    }).then((res) => res.json());
  },
  setClasses: (defaultClasses = false, isActiveClasses = false) => {
    let classNames = [],
      className = "";

    if (defaultClasses) {
      classNames = defaultClasses;
    }

    if (isActiveClasses) {
      classNames.push(
        ...Object.keys(isActiveClasses)
          .map((className) => isActiveClasses[className] && className)
          .filter((className) => className)
      );
    }

    className = classNames.join(" ");

    return className;
  },
  connect: (component_) => {
    return connect(
      (state) => whithoutType(state),
      (dispatch) => ({
        setRedux: (state) => dispatch(REDUX.ACTIONS.setRedux(state)),
      })
    )(component_);
  },
  timeCalculate: (list) => {
    let totalTime = {
      h: 0,
      min: 0,
    };
    list.forEach((item) => {
      let mn = item.duration.min ? item.duration.min : 0;
      totalTime.h += item.duration.h;
      totalTime.min += mn;
      if (totalTime.min >= 60) {
        totalTime.h += Math.floor(totalTime.min / 60);
        totalTime.min = totalTime.min % 60;
      }
    });
    return totalTime;
  },
  translateTime: (min) => {
    let total = {
      h: 0,
      min: 0,
    };
    total.min += min;
    if (min >= 60) {
      total.h = Math.floor(min / 60);
      total.min = total.min % 60;
    }
    return total;
  },
  sumCalculate: (list, lang) => {
    let totalSum = 0;
    list.forEach((item) => {
      let bigger = Math.max(
        parseFloat(item.cost_start.replace(/[^(\d|\.)]/g, '')),
        parseFloat(item.cost_finish.replace(/[^(\d|\.)]/g, ''))
      );

      totalSum += bigger;
    });
    return preparePrice(totalSum, lang);
  },
  renderPrice: (price, lang, discount) => {
    let totalPrice = '';

    if (typeof price === 'object') {
      let start = price.start;
      let finish = price.finish;
      start = start.replace(/[^(\d|\.)]/g, '');
      finish = finish.replace(/[^(\d|\.)]/g, '');
      const startResult = parseFloat(start),
        finishResult = parseFloat(finish);

      const preparedStart = preparePrice(startResult, lang),
        preparedFinish = preparePrice(finishResult, lang);

      if (
        startResult &&
        finishResult
      ) {

        totalPrice = `${preparedStart} - ${preparedFinish}`;
      } else if (!finishResult) {
        totalPrice = `${preparedStart}`;
      }
      else {
        totalPrice = `${preparedFinish}`;
      }
    } else {
      totalPrice = price;
    }

    let totalPriceDiscount;

    discount ? totalPriceDiscount = totalPrice - totalPrice * discount / 100 : totalPriceDiscount = 0;
    const isEn = /\/en\//g.test(window.location.href);
    // if ( startResult &&
    //   finishResult){
    //     totalPrice = `${preparedFinish}`
    //   } else {
    //     totalPrice = price;
    //   }
    let discountText =  isEn? " including your discount of " : " с учетом вашей скидки "

    switch (lang) {
      case 'rub':
        !discount ? totalPrice = totalPrice + 'р' : totalPrice = totalPriceDiscount.toFixed(1) + '₽' + discountText + discount + "%";
        break;
      case 'eur':
        !discount ? totalPrice = "€" + totalPrice : totalPrice = "€" + totalPriceDiscount.toFixed(1) + discountText + discount + "%";
        break;
      default:
        !discount ? totalPrice = "$" + totalPrice : totalPrice = "$" + totalPriceDiscount.toFixed(1) + discountText + discount + "%";
    }

    return totalPrice;
  },
  getError: (matchId, errosArr) => {
    let errorMsg = "";
    switch (matchId) {
      case "services":
        errorMsg = errosArr[0];
        break;
      case "calendar":
        errorMsg = errosArr[1];
        break;
      case "time":
        errorMsg = errosArr[2];
        break;
      default:
        errorMsg = "Temporary error";
    }
    return errorMsg;
  },
  daysInMonth: (month, year) => {
    return new Date(year, month, 0).getDate();
  },
  parserTimeJob: (jobArray) => {
    let allWorkingData = [];
    jobArray.split(";").forEach((time, i) => {
      if (time) {
        allWorkingData.push({
          id: i,
          workStart: time
            .replace(/[(A-z)]/gm, "")
            .replace(/:/gm, "")
            .replace(/-/gm, ":")
            .match(/^([^:]+)/gm),
          workFinish: time
            .replace(/[(A-z)]/gm, "")
            .replace(/:/gm, "")
            .replace(/-/gm, ":")
            .match(/:\d+([^\n]+)/gm),
        });
      }
    });
    return allWorkingData;
  },
  parserJob: (timeJob) => {
    let arrayTimeJob = timeJob.split(";"),
      parseTimeJob = [];
    arrayTimeJob.forEach((badString) => {
      if (badString) {
        parseTimeJob.push({
          days: badString.replace(/[:,0-9,\-,.]/gm, ""),
          time: badString
            .replace(/[(A-z)]/gm, "")
            .replace(/:/gm, "")
            .replace(/-/gm, ":"),
          timeStart: badString
            .replace(/[(A-z)]/gm, "")
            .replace(/:/gm, "")
            .replace(/-/gm, ":")
            .match(/^([^:]+)/gm)[0],
          timeBreak: badString
            .replace(/[(A-z)]/gm, "")
            .replace(/:/gm, "")
            .replace(/-/gm, ":")
            .match(/:\d+([^\n]+)/gm)[0]
            .replace(/:/gm, ""),
        });
      }
    });
    parseTimeJob.forEach((job) => {
      job.time_interval =
        (parseInt(job.timeBreak) - parseInt(job.timeStart)) * 60;
    });
    return parseTimeJob;
  },
  renderFreeTimeMaster: (arrayWithDate, profile) => {
    let mainInterval = [];

    return mainInterval;
  },
  prevDate: (date) => {
    let prevDate = {};
    if (
      date.value !== 0 &&
      date.value === new Date().getMonth() - 1 &&
      date.year === new Date().getFullYear()
    ) {
      prevDate = date;
    }
    return prevDate;
  },
  nowDate: (date) => {
    let currentDate = {};
    if (
      date.value === new Date().getMonth() &&
      date.year === new Date().getFullYear()
    ) {
      currentDate = date;
    }
    return currentDate;
  },
  nextDate: (date, globalDate) => {
    let nextDate = {};
    if (
      date.value !== 11 &&
      date.value === new Date().getMonth() + 1 &&
      date.year === new Date().getFullYear()
    ) {
      nextDate = date;
    }
    if (date.value === 11) {
      globalDate.find((finderMonth) => {
        if (
          finderMonth.value === 0 &&
          finderMonth.year === new Date().getFullYear() + 1
        ) {
          nextDate = finderMonth;
        }
      });
    }
    return nextDate;
  },
  getLink: (url, isSubPage, alreadyVerify, useMobileVerify) => {
    let redirect = "";
    switch (url) {
      case "list":
        return (redirect = "services")
      case "policy":
        return (redirect = "ready")
      case "services":
        return (redirect = "calendar")
      case "calendar":
        return (redirect = "time");
      case "time":
        if (alreadyVerify) {
          redirect = "ready";
        } else {
          redirect = "phone";
        }
        return redirect;
      case "phone":
        return (redirect = useMobileVerify ? "check " : "ready");
      case "check ":
        if (isSubPage) {
          redirect = "check ";
        } else {
          redirect = "ready";
        }
        return redirect;
      default:
        return (redirect = "404");
    }
  },
  checkError: (url, props, lengthPhone) => {
    let isError = false;
    switch (url) {
      case "calendar":
        !props[0] ? (isError = true) : (isError = false);
        break;
      case "time":
        !props[1] ? (isError = true) : (isError = false);
        break;
      case "phone":
        props[2].length < lengthPhone || !props[3]
          ? (isError = true)
          : (isError = false);
        break;
      case "check":
        !props[3] ? (isError = true) : (isError = false);
        !props[5] ? (isError = true) : (isError = false);
      // case "check2":
      // !props[5] ? (isError = true) : (isError = false);
      default:
        isError = false;
    }
    return isError;
  },
  getCountryCode: (collection, userLocation) => {
    let returnPhoneCode = {};
    switch (userLocation.location.country) {
      case "RU":
        let translateRussia = "Россия";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateRussia.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      case "US":
        let translateUSA = "США";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateUSA.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      case "NA":
        let translateNA = "США";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateNA.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      case "DE":
        let translateDe = "Германия";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateDe.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      case "LT":
        let translateLT = "Литва";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateLT.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      case "BY":
        let translateBy = "Беларусь";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateBy.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      case "UA":
        let translateUA = "Украина";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateUA.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      case "ES":
        let translateES = "Испания";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateES.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      case "IL":
        let translateIL = "Израиль";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateIL.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      case "LV":
        let translateLV = "Латвия";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateLV.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      case "GE":
        let translateGE = "Грузия";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateGE.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      case "EST":
        let translateEST = "Эстония";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateEST.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      case "FI":
        let translateEE = "Финляндия";
        returnPhoneCode = collection.find(
          (serchCountry) =>
            translateEE.toUpperCase() === serchCountry.country.toUpperCase()
        );
        break;
      default:
        returnPhoneCode = { id: 999, code: 1, country: "США", count: 10 };
    }
    return returnPhoneCode;
  },
  maxLengthPhones: [
    {
      code: 7,
      length: 14,
      purelength: 10,
    },
    {
      code: 380,
      length: 14,
      purelength: 10,
    },
    {
      code: 1,
      length: 14,
      purelength: 10,
    },
    {
      code: 49,
      length: 15,
      purelength: 11,
    },
    {
      code: 34,
      length: 13,
      purelength: 9,
    },
    {
      code: 995,
      length: 13,
      purelength: 9,
    },
    {
      code: 358,
      length: 12,
      purelength: 8,
    },
    {
      code: 371,
      length: 12,
      purelength: 8,
    },
    {
      code: 370,
      length: 12,
      purelength: 8,
    },
    {
      code: 372,
      length: 12,
      purelength: 8,
    },
    {
      code: 972,
      length: 13,
      purelength: 9,
    },
    {
      code: 375,
      length: 13,
      purelength: 9,
    },
  ],
  timer: (callback) => {
    let timer,
      limit = 10;
    timer = setInterval(() => {
      if (limit > 0) {
        callback();
        limit -= 1;
      }
    }, 1000);
    if (limit < 1) {
      clearInterval(timer);
    }
  },
  timeBuild: (dataWithDates, date, user, userDuration) => {
    if (user.break && dataWithDates.length) {
      dataWithDates.push({
        ev_date: "break",
        ts: user.break_start,
        tf: user.break_finish,
      });
    }
    let ranges = [];
    dataWithDates.forEach((iterationDate) => {
      ranges.push({
        ev_date: iterationDate.ev_date,
        duration: iterationDate.duration,
        interval: {
          start: new Date(
            `${date.year}/${date.month + 1}/${date.day} ${iterationDate.ts}`
          ),
          end: new Date(
            `${date.year}/${date.month + 1}/${date.day} ${iterationDate.tf}`
          ),
        },
      });
    });
    const masterWorkTime = ranges.find((event) => event.ev_date === null),
      breakTime = ranges.find((event) => event.ev_date === "break");

    let masterOpenDates = [],
      withoutIntervals = [];
    ranges.forEach((event) => {
      if (event.ev_date !== null && event.ev_date !== "break") {
        masterOpenDates.push(event);
      }
    });

    if (!masterOpenDates.length) {
      ranges.forEach((event) => {
        if (event.ev_date === null) {
          let newEvent = { ...event };
          newEvent.ev_date = "some_active";
          withoutIntervals.push(newEvent);
        }
      });
    }

    const getEntryDuration = (time) => {
      return (
        getMinutes(time.interval.start) + getHours(time.interval.start) * 60
      );
    };
    const getEntryDurationUpdate = (time) => {
      return getMinutes(time) + getHours(time) * 60;
    };
    const getOutputDuration = (time) => {
      return getMinutes(time.interval.end) + getHours(time.interval.end) * 60;
    };

    let willArray = [];
    let activeTimes = [];

    const entryDates = (arrWithDates) => {
      arrWithDates.forEach((date) => {
        if (date.ev_date !== null && date.ev_date !== "break") {
          let dateStartDur = getEntryDuration(date),
            dateEndDur = getOutputDuration(date);
          if (breakTime) {
            let breakStartDur = getEntryDuration(breakTime),
              breakEndDur = getOutputDuration(breakTime);

            let LunchOverlappFullPeriod =
              dateStartDur <= breakStartDur && dateEndDur >= breakEndDur;
            let LunchInPeriod =
              dateStartDur <= breakStartDur && dateEndDur > breakEndDur;
            let LunchOverlappStartPeriod =
              dateStartDur <= breakStartDur && dateEndDur <= breakEndDur;
            let LunchOverlappEndPeriod =
              dateStartDur >= breakEndDur &&
              areIntervalsOverlapping(breakTime.interval, date.interval);
            let LunchNotInPeriod = !areIntervalsOverlapping(
              breakTime.interval,
              date.interval
            );
            if (LunchNotInPeriod) {
              activeTimes.push({
                date: { start: date.interval.start, end: date.interval.end },
                duration: dateStartDur + dateEndDur,
              });
              return;
            }

            if (LunchOverlappFullPeriod) {
              activeTimes.push({
                date: {
                  start: date.interval.start,
                  end: breakTime.interval.start,
                },
              });
              activeTimes.push({
                date: { start: breakTime.interval.end, end: date.interval.end },
              });
              return;
            }

            if (LunchInPeriod) {
              activeTimes.push({
                date: {
                  start: date.interval.start,
                  end: breakTime.interval.start,
                },
                duration: dateStartDur + breakStartDur,
              });

              activeTimes.push({
                date: { start: breakTime.interval.end, end: date.interval.end },
                duration: breakEndDur + dateEndDur,
              });
              return;
            }
            if (LunchOverlappStartPeriod) {
              activeTimes.push({
                date: { start: breakTime.interval.end, end: date.interval.end },
                duration: breakEndDur + dateEndDur,
              });
            }
            if (LunchOverlappEndPeriod) {
              activeTimes.push({
                date: {
                  start: date.interval.start,
                  end: breakTime.interval.start,
                },
                duration: dateStartDur + breakStartDur,
              });
            }
          } else {
            activeTimes.push({
              date: { start: date.interval.start, end: date.interval.end },
              duration: dateStartDur + dateStartDur,
              active: false,
            });
          }
        }
      });
      let workTimes = [];
      workTimes.push({
        date: getEntryDurationUpdate(masterWorkTime.interval.start),
        active: false,
      });
      workTimes.push({
        date: getEntryDurationUpdate(masterWorkTime.interval.end),
        active: false,
      });
      activeTimes.forEach((activeTime) => {
        let ServiceDurOverlappFullPeriod =
          getEntryDurationUpdate(activeTime.date.start) + userDuration <=
          getEntryDurationUpdate(activeTime.date.end);

        if (ServiceDurOverlappFullPeriod) {
          activeTime.date.end = subMinutes(activeTime.date.end, userDuration);
          if (
            getEntryDurationUpdate(activeTime.date.end) !==
            getEntryDurationUpdate(activeTime.date.start)
          ) {
            workTimes.push({
              date: getEntryDurationUpdate(activeTime.date.end),
              active: true,
            });
            workTimes.push({
              date: getEntryDurationUpdate(activeTime.date.start),
              active: true,
            });
          } else {
            workTimes.push({
              date: getEntryDurationUpdate(activeTime.date.end),
              active: true,
            });
          }
          // workTimes = [...new Map(workTimes.map(item =>
          //   [item[date], item])).values()];
          for (
            let i = getEntryDurationUpdate(activeTime.date.start);
            i < getEntryDurationUpdate(activeTime.date.end);
            i++
          ) {
            let isAlreadyUseValue = workTimes.find((item) => item.date === i);
            if (!isAlreadyUseValue) {
              if (i % 15 === 0) {
                workTimes.push({
                  date: i,
                  active: true,
                });
              }
            }
          }
        }
      });
      for (
        let i = getEntryDurationUpdate(masterWorkTime.interval.start);
        i <= getEntryDurationUpdate(masterWorkTime.interval.end);
        i++
      ) {
        let isAlreadyUseValue = workTimes.find((item) => item.date === i);
        if (!isAlreadyUseValue) {
          if (i % 15 === 0) {
            workTimes.push({
              date: i,
              active: false,
            });
          }
        }
      }

      workTimes.sort((a, b) => {
        if (isEqual(a.date, b.date)) return 0;
        return isBefore(a.date, b.date) ? -1 : 1;
      });
      for (let i = 0; i < workTimes.length - 1; i++) {
        let isFirst = i === 0,
          isLast = i === workTimes.length - 1;

        if (isFirst && workTimes[i + 1].date - workTimes[i].date < 15) {
          workTimes = workTimes.filter((timeJob) => {
            if (workTimes[i + 1].date !== timeJob.date) {
              return timeJob;
            }
          });
        }

        if (!isFirst && !isLast) {
          if (workTimes[i].date % 15 !== 0) {
            if (!workTimes[i - 1].active) {
              workTimes = workTimes.filter((timeJob) => {
                if (workTimes[i + 1].date !== timeJob.date) {
                  return timeJob;
                }
              });
            } else if (!workTimes[i + 1].active) {
              workTimes = workTimes.filter((timeJob) => {
                if (workTimes[i + 1].date !== timeJob.date) {
                  return timeJob;
                }
              });
            }
          }
        }
      }
      workTimes.forEach((item) => {
        item.date = set(
          new Date(`${date.year}/${date.month + 1}/${date.day}`),
          { minutes: item.date }
        );
      });
      willArray = workTimes;
    };
    // if (masterOpenDates.length) {
    //   entryDates(masterOpenDates);
    // }
    // else {
    //   entryDates(withoutIntervals);
    // }
    masterOpenDates.length
      ? entryDates(masterOpenDates)
      : entryDates(withoutIntervals);
    //Will using for generate empty fields
    let firstPosTime = getMinutes(willArray[0].date),
      beginFirstСell = firstPosTime !== 0 ? true : false;

    if (beginFirstСell) {
      if (getMinutes(willArray[1].date) <= 30) {
        willArray.unshift({
          date: new Date(`2019/10/24`),
          active: false,
          empty: true,
        });
      }
      if (getMinutes(willArray[1].date) > 30) {
        willArray.unshift({
          date: new Date(`2019/10/24`),
          active: false,
          empty: true,
        });

        willArray.unshift({
          date: new Date(`2019/10/24`),
          active: false,
          empty: true,
        });
      }
    }
    return willArray;
  },
  hoursToMinutes: (data) => {
    return data.h * 60 + data.min;
  },
  durationEvent: (currentDate, duration, props) => {
    currentDate = new Date(currentDate);

    let startDate = api.translateTime(
      getMinutes(currentDate) + getHours(currentDate) * 60
    ),
      endTime = api.translateTime(
        duration + (getMinutes(currentDate) + getHours(currentDate) * 60)
      ),
      leftInterval = set(currentDate, {
        minutes: startDate.min,
        hours: startDate.h,
      }),
      rightInterval = set(currentDate, {
        minutes: endTime.min,
        hours: endTime.h,
      });
    return `${Format(leftInterval, props)}-${Format(rightInterval, props)}`;
  },
  _durationEvent: (currentDate, duration, direction, props) => {
    currentDate = new Date(currentDate);
    let startDate = api.translateTime(
      getMinutes(currentDate) + getHours(currentDate) * 60
    ),
      endTime = api.translateTime(
        duration + (getMinutes(currentDate) + getHours(currentDate) * 60)
      ),
      leftInterval = set(currentDate, {
        minutes: startDate.min,
        hours: startDate.h,
      }),
      rightInterval = set(currentDate, {
        minutes: endTime.min,
        hours: endTime.h,
      });

    return Format(direction === "begin" ? leftInterval : rightInterval, props)
  },
  checkMasterDataIsFull: (result, dispatch, props) => {
    let masterDataIsFull = false;

    if (!result || !result.length) {
      masterDataIsFull = false;
      dispatch({ masterDataIsFull });

      return;
    }

    let main = getMainServiceStore(props.serviceStore);
    let masterService = result[0]
    if (main) {
      masterService = result.find(result_ => result_._id === main._id);
    }

    dispatch({ isTimeInterval: masterService.time_interval ? masterService.time_interval : false });

    if (masterService.time_interval) {
      masterDataIsFull = true;
    }

    else {
      masterDataIsFull = false;
    }

    dispatch({ masterDataIsFull });
  },
};

export default api;
/* eslint-enable */