import i18n from "i18next";
import ru from './ru.json';
import en from './en.json';

import { reactI18nextModule } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const lng = /\/en/g.test(window.location.href) ? 'en' : 'ru'
// the translations
// (tip move them in a JSON file and import them)
i18n
  .use(reactI18nextModule)
  .use(LanguageDetector)// passes i18n down to react-i18next
  .init({
    resources: { ru, en },
    lng,

    keySeparator: false, // we do not use keys in form messages.welcome
    preload: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;