import React from 'react';
import api from '../api';

class TimePicker extends React.Component {
  onClick = (e, data) => {
    const { selectTime } = this.props;
    selectTime(e, {
      active: data.active,
      time: api.format(data.date, this.props),
      dateUTC: data.date
    })
  }

  render() {
    const { selectTime, data, isTimeInterval, profile } = this.props;
    const isHeapEvents = profile.find(({ heap_events }) => heap_events)
    const classHiddenTime = !isTimeInterval ? "grid__time_picker disable" : "grid__time_picker disable d-none"
    const classActiveTime = `grid__time_picker ${isHeapEvents ? ' grid__time_picker_heap' : ' '} ${data.show ? ' grid__time_picker_heap_show' : ''}`
    return (
      <>
        {data.active && data.date &&
          <div
            data-format={data.date}
            data-date={api.format(data.date, this.props)}
            onClick={(e) => this.onClick(e, data)}
            className={classActiveTime}>
            {api.format(data.date, this.props)}
          </div>
        }
        {!data.active && data.date &&
          <div onClick={(e) => selectTime(e, api.format(data.date, this.props))} className={classHiddenTime}>
            {api.format(data.date, this.props)}
          </div>
        }
        {data.empty &&
          <div className="grid__time_picker disable hidden"></div>
        }
      </>
    )
  }
}

export default api.connect(TimePicker);

