
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import api, { getCookie } from '../api';

class ErrorModal extends Component {
  closeModal = () => {
    this.props.setRedux({
      showError: false
    });
     

    if (getCookie('carabine')) {
      this.props.history.push(api.prepareUrl(`/master/${this.props.profile[0].id}/${this.props.firstStep}`))
    }
    else {
      this.props.history.push(api.prepareUrl(`/master/${this.props.profile[0].id}`))
    }
  }
  render() {
    const { t } = this.props;
    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
      >
        <div className="wrapper__modal">
          <div className="modal__wrapper">
            <h2 class="modal__title">{t('error_titleModal')}</h2>
            <span className="modal__description">
              {t('error__failed_saveEvent')}
            </span>
            <p onClick={this.closeModal} className="again">{t('error_tryAgain')}</p>
          </div>
        </div>
      </Modal>
    )
  }
}
const ErrorModalCls = withNamespaces()(ErrorModal)
export default api.connect(ErrorModalCls);