import React from 'react';
import api from '../api';
export default function SubError({ expression, text }) {
  return (
    <div className={api.setClasses(['block__error'], { active__error: expression })}>
      <div className="error__message">
        <div className="wrapper_in_block">
          <span>{text}</span>
        </div>
      </div>
    </div>
  );
}