import React from 'react';
import api from '../../api';
import HeaderMenager from '../headerMenager';
import Button from '../../common/button';
import Field from '../../common/field';
import { debounce } from 'lodash';
import SubError from '../../common/subErrors';

class Verification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      blur: false,
      userSendAgain: false,
      notValidCode: false,
      isFinished: true,
      codeWithoutVerify: null
    }
    this.interval = {};
    this.Afiled = React.createRef();
    this.Bfiled = React.createRef();
    this.Cfiled = React.createRef();
    this.Dfiled = React.createRef();
    this.inputWrrapper = React.createRef();
    this.delay = debounce(this._checkCode, 500);
  }
  tick = () => {
    this.props.setRedux({
      timer: this.props.timer - 1
    });
  }
  _fetchCode = () => {
    let params = {
      phone: this.props.selectPhoneCode ? `+${this.props.selectPhoneCode.code}${this.props.userOrderPhone}` : `+7${this.props.userOrderPhone}`
    };
    api.sendCode(params).then(res => {
      if (res) {
        this.props.setRedux(
          {
            verifCodeRender: res
          }
        )
      }
    });
  }
  componentDidMount() {

    window.scrollTo(0, 0);
    this.interval = setInterval(this.tick, 1000);
    if (!this.props.verifCodeRender) {
      this._fetchCode();
    }
    this.props.setRedux({
      verifyPage: true,
      helpView: false
    });
  }
  componentWillUnmount() {
    window.clearInterval(this.interval);
  }
  _checkCode = () => {
    const {
      verifyCodeA,
      verifyCodeB,
      verifyCodeC, verifyCodeD,
      serviceStore
    } = this.props;

    const { codeWithoutVerify } = this.state;

    let codeVerify = [verifyCodeA, verifyCodeB, verifyCodeC, verifyCodeD],
      codeIsFull = codeVerify.every(code => code);

    if (codeIsFull) {
      // parseInt(this.props.verifCodeRender)
      let conditions = [
        {
          verify: {
            corresponds: (parseInt(codeVerify.join('')) === parseInt(this.props.verifCodeRender)),
            type: 'verify'
          },
        },
        {
          verify: {
            corresponds: (parseInt(codeVerify.join('')) === parseInt(codeWithoutVerify)),
            type: 'withoutVerify'
          }
        }
      ], currentCondition = conditions.find((condition) => {
        if (condition.verify.corresponds === true) {
          return condition;
        }
        return false;
      });
      serviceStore.forEach((service) => {
        service.timeInMin = api.hoursToMinutes(service.duration)
      });
      if (currentCondition) {
        this.props.setRedux({
          isTypeVerify: currentCondition.verify.type === 'verify' ? true : false,
          userHaveAuth: true
        });
        const { location, profile } = this.props;


        location.history.push(api.prepareUrl(`/master/${profile[0].id}/ready`))


      }
      else {
        this.setState({
          notValidCode: true
        });
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.timer < 1) {
      window.clearInterval(this.interval);
    }
    if (this.props.wasChangePhone && !this.props.blockingNext && !this.props.editPhonePage) {
      this.props.setRedux({
        timer: 60,
        helpView: false,
        againSend: false,
        wasChangePhone: false
      });
      this._fetchCode();
      this.interval = setInterval(this.tick, 1000);
    }
    if (this.inputWrrapper.current) {
      this.inputWrrapper.current.childNodes.forEach((item) => {
        if (item.value) {
          if (item.nextSibling && !item.nextSibling.value) {
            item.nextSibling.focus();
          }
        }
      });
    }

  }
  writeCode = (e) => {
    this.setState({
      notValidCode: false
    });
    if (e.target.value.length < 2) {
      if (e.target.classList.contains('a')) {
        this.props.setRedux({
          verifyCodeA: e.target.value.replace(/\D/gm, '')
        });
      }
      else if (e.target.classList.contains('b')) {
        this.props.setRedux({
          verifyCodeB: e.target.value.replace(/\D/gm, '')
        });
      }
      else if (e.target.classList.contains('c')) {
        this.props.setRedux({
          verifyCodeC: e.target.value.replace(/\D/gm, '')
        });
      }
      else if (e.target.classList.contains('d')) {
        this.props.setRedux({
          verifyCodeD: e.target.value.replace(/\D/gm, '')
        });
      }
    }
    this.delay();

  }
  sendAgain = () => {
    this._fetchCode();
    this.props.setRedux({
      againSend: true
    });
  }
  openHelp = () => {
    function _getRandomNumber(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }
    let code = [_getRandomNumber(9), _getRandomNumber(9), _getRandomNumber(9), _getRandomNumber(9)].join('');
    this.setState({ codeWithoutVerify: code });

    this.props.setRedux({
      helpView: true
    });
  }
  editOpen = () => {
    this.props.setRedux({
      editPhonePage: true
    });
  }
  backSpaceEvent = (e) => {
    if (e.which === 8) {
      this.setState({
        notValidCode: false
      });
      if (e.target.previousSibling && e.target.previousSibling.value) {
        e.target.previousSibling.focus();
        e.target.previousSibling.value = '';
        e.target.value = '';
        if (e.target.classList.contains('a')) {
          this.props.setRedux({
            verifyCodeA: e.target.value.replace(/\D/gm, '')
          });
        }
        else if (e.target.classList.contains('b')) {
          this.props.setRedux({
            verifyCodeB: e.target.value.replace(/\D/gm, '')
          });
        }
        else if (e.target.classList.contains('c')) {
          this.props.setRedux({
            verifyCodeC: e.target.value.replace(/\D/gm, '')
          });
        }
        else if (e.target.classList.contains('d')) {
          this.props.setRedux({
            verifyCodeD: e.target.value.replace(/\D/gm, '')
          });
        }
        if (e.target.previousSibling.classList.contains('a')) {
          this.props.setRedux({
            verifyCodeA: e.target.value.replace(/\D/gm, '')
          });
        }
        else if (e.target.previousSibling.classList.contains('b')) {
          this.props.setRedux({
            verifyCodeB: e.target.value.replace(/\D/gm, '')
          });
        }
        else if (e.target.previousSibling.classList.contains('c')) {
          this.props.setRedux({
            verifyCodeC: e.target.value.replace(/\D/gm, '')
          });
        }
        else if (e.target.previousSibling.classList.contains('d')) {
          this.props.setRedux({
            verifyCodeC: e.target.value.replace(/\D/gm, '')
          });
        }
      }
    }
  }
  render() {
    const { t, selectPhoneCode } = this.props;
    const activeIcon = this.props.verifyCodeA && this.props.verifyCodeB && this.props.verifyCodeC && this.props.verifyCodeD;
    const currentLength = selectPhoneCode ? api.maxLengthPhones.find((item) => item.code === selectPhoneCode.code).length : 14;
    const pureLength = selectPhoneCode ? api.maxLengthPhones.find((item) => item.code === selectPhoneCode.code).purelength : 10;
    const stringForError = `${t('contactDet__subError_phone_smallLength')} ${pureLength} ${t('isNumbers')}`
    return (
      <>
        <HeaderMenager
          idUrl={this.props.idUrl}
          step={this.props.step}
          lastPage={true}
          currentPos={4}
          history={this.props.history}
          serviceTitle={t('verify__title')}
          t={t}
        />

        <section className="section-usage verify">
          {!this.props.editPhonePage &&
            <>
              <div className="services__verification">
                <div className="verification__row">
                  <div className="wrapper_in_block verification__wrapper">
                    <div className={api.setClasses(['verification__icon'], { activeIcon: activeIcon })}></div>
                    <div ref={this.inputWrrapper} onBlur={this.bluring} className="verification__fields">
                      <input onKeyDown={this.backSpaceEvent} autoFocus value={this.props.verifyCodeA} onChange={this.writeCode} maxLength="1" className="field__number a" type={window.screen.width > 1025 ? "text" : "tel"} />
                      <input onKeyDown={this.backSpaceEvent} value={this.props.verifyCodeB} onChange={this.writeCode} maxLength="1" className="field__number b" type={window.screen.width > 1025 ? "text" : "tel"} />
                      <input onKeyDown={this.backSpaceEvent} value={this.props.verifyCodeC} onChange={this.writeCode} maxLength="1" className="field__number c" type={window.screen.width > 1025 ? "text" : "tel"} />
                      <input onKeyDown={this.backSpaceEvent} value={this.props.verifyCodeD} onChange={this.writeCode} maxLength="1" className="field__number d" type={window.screen.width > 1025 ? "text" : "tel"} />
                    </div>
                  </div>
                </div>
                <SubError expression={this.state.notValidCode} text={t('verify__codeError')} />
                <div className="verification__timer">
                  <div className="wrapper_in_block">
                    {this.props.timer > 0 &&
                      <span>{t('verify__sendAgain')} {this.props.timer} {t('verify__durationTimer')}</span>
                    }
                    {!this.props.againSend && !this.props.timer &&
                      <Button action={this.sendAgain} title={t('verify__btnSendAgain')} className="verification__repeat_btn" />
                    }
                    {this.props.againSend && !this.props.helpView &&
                      <div onClick={this.openHelp} className="verification__troubles">
                        <div>{t('verify__whatIcanDo')}</div>
                      </div>
                    }
                    {this.props.againSend && this.props.helpView &&
                      <div className="verification__troubles">
                        <span className="troubles__info">{t('verify__checkSms')}<span>{t('verify__iffDontHaveCode')}</span> <b>{this.state.codeWithoutVerify}</b>.
                        <span>{t('verify__masterCall')}</span> <strong>+{this.props.selectPhoneCode.code ? this.props.selectPhoneCode.code : this.props.selectPhoneCode} {this.props.userOrderPhone}</strong>.
                        {t('verify__ifCannotConnect')}<span><b>{t('verify__wecandeleteIt')}</b></span></span>
                        <div onClick={this.editOpen} className="troubles__edit">{t('verify__editPhoneBtn')}</div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </>
          }
          {this.props.editPhonePage &&
            <>
              <div className="services">
                <div className="wrapper_in_block">
                  <div className="services__steps">
                    <p className="steps__title">{t('editPhone__title')}</p>
                  </div>
                </div>
              </div>
              <div className="services__contacts">
                <div className="services__field">
                  <div className="wrapper_in_block">
                    <Field type={'tel'} />
                  </div>
                </div>
                {this.props.blockingNext && this.props.userOrderName && !this.props.userOrderPhone &&
                  <SubError text={t('contactDet__subError_phone_empty')} expression={this.props.blockingNext && this.props.userOrderName && !this.props.userOrderPhone} />
                }
                {this.props.blockingNext && this.props.userOrderName && this.props.userOrderPhone && this.props.userOrderPhone.length < currentLength &&
                  <SubError text={stringForError} expression={this.props.blockingNext && this.props.userOrderName && this.props.userOrderPhone && this.props.userOrderPhone.length < currentLength} />
                }
              </div>
            </>
          }
        </section>
      </>
    )
  }
}
export default api.connect(Verification);