import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import api, { getCookie } from '../api';
import Button from '../common/button';
import ReadySerivce from '../components/readyService';
import { set, getHours, getMinutes, format, getMonth } from 'date-fns';
import AddToCalendar from 'react-add-to-calendar';
import createHistory from 'history/createBrowserHistory'
export class Ready extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [],
      error: false,
      resultSave: [],
      fullOrder: this.props.serviceStore,
      arrOrder: JSON.parse(localStorage.getItem('fullOrder')) || [],
      timeArrOrder: this.props.userDateOrder,

    }
    this.icsItems = React.createRef();
  }
  componentDidMount() {
    this.props.setRedux({
      readyActive: true,
      eventId: false
    });

    const {
      userTimeOrder,
      userTimeOrderDateFormat,
      profile,
      userOrderName,
      userDateOrder,
      selectPhoneCode,
      serviceStore,
      userOrderPhone,
      userInstaName = '',
      userSurnameName = ''
    } = this.props;
    serviceStore.forEach((service) => {
      service.timeInMin = api.hoursToMinutes(service.duration)
    });
    const profileMaster = profile[0],
      userPhoneWithoutCode = userOrderPhone,
      userSelectCode = selectPhoneCode ? '+'.concat(selectPhoneCode.code.toString()) : '+7',
      userPhone = userSelectCode.concat(userPhoneWithoutCode),
      userDuration = api.timeCalculate(serviceStore).h * 60 + api.timeCalculate(serviceStore).min,
      surname = userSurnameName || userOrderName.match(/\s(.*)/gm)?.[0] || '',
      firstName = !userSurnameName  && userOrderName.match(/([^ ]+)/gm) ? userOrderName.match(/([^ ]+)/gm)[0] : userOrderName,

      requestBody = {
        "p_id_master": profileMaster.id,
        "p_id_address": 0,
        "p_event_date": `${userDateOrder.year}-${userDateOrder.month + 1}-${userDateOrder.day}`,
        "p_time_begin": api._durationEvent(userTimeOrderDateFormat, userDuration, 'begin', this.props),
        "p_time_finish": api._durationEvent(userTimeOrderDateFormat, userDuration, 'end', this.props),
        "p_cost": api.sumCalculate(serviceStore, serviceStore[0].currency),
        "p_surname": surname,
        "p_name": firstName,
        "p_phone": userPhone,
        "p_confirmed": this.props.isTypeVerify ? true : false,
        "p_address": profileMaster.address
      };

      if (profileMaster.required_instagram) requestBody['p_instagram'] = userInstaName;

    api.saveEvent(requestBody).then(res => {
      this.setState({ resultSave: res || [] });
      if (res[0].id_e) {
        let date = new Date(`${this.props.userDateOrder.year}/${this.props.userDateOrder.month + 1}/${this.props.userDateOrder.day}`)
        let sendDate = format(date, this.props.t('format_date'))
        api.notifyMaster(
          {
            isSave: true,
            profile: profileMaster,
            serviceName: this.props.serviceStore.reduce(function (a, b) { return a.title_service.length > b.title_service.length ? a : b; }),
            type: 'Create event',
            user: {
              timeOrder: userTimeOrder,
              date: sendDate
            }
          }
        );
        this.props.setRedux({
          eventId: res[0].id_e
        });
        let requestBodySrv = serviceStore.map((service) => {
          let { price } = service;

          if (price.split('-')[1]) {
            price = price.split('-')[1].trim()
          }

          price = parseInt(price.replace(/\D/gm, ''))

          let discount = res[0]?.sale ||  0;
          return {
          "p_id_service": service.id_service,
          "p_cost": price * (1 - discount / 100),
          "p_id_event": res[0].id_e,
          "p_duration": service.timeInMin
        }});

        api.saveServices(requestBodySrv);
      }
    }).catch(err => {
      this.props.setRedux({
        showError: true
      });
    });
    window.scrollTo(0, 0);
    this.icsItems.current.setState({
      optionsOpen: true
    }, (() => {
      let links = [];
      const getParentList = document.querySelector('.react-add-to-calendar__dropdown ul');
      getParentList.childNodes.forEach((list) => {
        links.push(
          {
            type: list.children[0].className,
            link: list.children[0].href
          }
        )
      });
      this.setState({
        links: links
      });
    }));
    
  }
  saveEventToCalendar = () => {
    const { links } = this.state;
    const isApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
    if (getCookie('carabine')) {

      if (!isApple) {
        const googleCalendar = links.find((link) => link.type === 'google-link');
        const link = document.createElement('a');
        link.href = googleCalendar.link;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      else {
        document.querySelector('.apple-link').click();
      }
    }
    else {
      const history = createHistory();
      history.go(0)
    }
  }
  newEvent = async () => {
    const { location, profile, firstStep, masterList } = this.props;

    let masterId;
    if (getCookie('carabine')) {
      masterId = profile[0].id;
      if (firstStep === 'list') {
        masterId = masterList[0].id_master;
      }

      await this.fetchData(getCookie, masterId, true);
      location.history.push(api.prepareUrl(this.props.startingUrl))
    }
    else {
      const history = createHistory();
      history.go(0)

    }
  }
  deleteEvent = () => {
    if (getCookie('carabine')) {
      this.props.setRedux({
        showModal: true
      });
    }
    else {
      const history = createHistory();
      history.go(0)
    }
  }

  fetchData = async (getCookie, masterId, newEvent = false) => {
    const clearData = {
      serviceStore: [],
      masterList: [],
      profile: [],
      availableMasterDays: [],
      userHaveAuth: getCookie('carabine') ? true : false,
      userOrderName: getCookie('carabine') ? this.props.userOrderName : '',
      userOrderPhone: getCookie('carabine') ? this.props.userOrderPhone : '',
      verifyCodeA: '',
      verifyCodeB: '',
      verifyCodeC: '',
      verifyCodeD: '',
      userTimeOrder: '',
      userDateOrder: '',
      userVerify: getCookie('carabine') ? this.props.userVerify : false,
    }
    this.props.setRedux(clearData);

    if (this.props.firstStep === 'list' && !newEvent) {
      this.setDependeces();
      await this.getSaloonData(masterId);
      return;
    }

    if (this.props.firstStep === 'services') {
      this.setDependeces();
      await this.getProfileData(masterId, Object.assign({}, this.props, clearData));
      return;
    }

    if (newEvent) {
      this.setDependeces();
      await this.getProfileData(masterId, Object.assign({}, this.props, clearData));
      await this.getSaloonData(masterId);
      return;
    }
  }

  setDependeces = () => {
    this.props.setRedux({
      isPreloaderActive: true,
    });
  }

  getSaloonData = async (masterId) => {
    const res = await api.fetchSaloonData(masterId);
    this.props.setRedux({
      masterList: res,
    });
    setTimeout(() => {
      this.props.setRedux({
        isPreloaderActive: false,
      });
    })
  }

  getProfileData = async (masterId, newProps) => {
    const resultData = await api.fetchData(masterId);
    if (resultData.length) {
      api.checkMasterDataIsFull(resultData, this.props.setRedux, newProps);
      resultData.map((item, i) => {
        item['_id'] = i;
        item['duration'] = api.translateTime(item.duration);
        if (this.props.firstStep === 'list') {
          item['address'] = this.props.masterList[0].address;
        }
        return item;
      });
      this.props.setRedux({ profile: resultData });

      const result = await Promise.all([
        api.fetchAvailableDays({
          id: resultData[0].id,
          masterWork: api.parserTimeJob(resultData[0].time_job),
          month: this.props.selectMonth + 1,
          year: this.props.selectYear
        }),
        api.fetchLocation(),
        api.fetchCodePhone()
      ]);

      result[0].forEach((day) => {
        day.edate = day.edate.match(/(?:\-)(\d\d)/gm)[1].replace(/-/, '')
      });
      this.props.setRedux({ availableMasterDays: result[0] });

      if (result[1]) {
        this.props.setRedux({ userLocation: result[1] });
      }

      if (result[2]) {
        this.props.setRedux({ phoneCode: result[2] });
      }
      setTimeout(() => {
        this.props.setRedux({
          isPreloaderActive: false,
        });
      })
    } else {
      this.props.isEnglish ? this.props.history.push("/en/404") :  this.props.history.push("/404");
    }
  }

  render() {
    //////////////
    const {
      userTimeOrderDateFormat,
      userDateOrder,
      serviceStore,
      profile,
      t } = this.props;

    let userDuration, date, getEndTime, startDatetime, endDateTime, moreLang, event;
    if (this.props.serviceStore.length) {
      userDuration = api.timeCalculate(serviceStore).h * 60 + api.timeCalculate(serviceStore).min;
      let c = api.durationEvent(userTimeOrderDateFormat, userDuration, this.props);
      localStorage.setItem("time", c);
      date = set(new Date(userTimeOrderDateFormat), { month: getMonth(new Date(userTimeOrderDateFormat)) });
      getEndTime = api._durationEvent(userTimeOrderDateFormat, userDuration, 'end', this.props);
      startDatetime = format(date, `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`);
      endDateTime = set(date, {
        hours: getHours(new Date(`${userDateOrder.year}/${userDateOrder.month + 1}/${userDateOrder.day} ${getEndTime}`)),
        minutes: getMinutes(new Date(`${userDateOrder.year}/${userDateOrder.month + 1}/${userDateOrder.day} ${getEndTime}`))
      });
      endDateTime = format(endDateTime, `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`);
      moreLang = this.props.serviceStore.reduce(function (a, b) { return a.title_service.length > b.title_service.length ? a : b; });
      let addressEvent = `${profile[0].address}`;
      let regExpAdress = profile[0].address.match(/[,\.]/gm);
      if (regExpAdress) {
        regExpAdress.forEach((item) => {
          addressEvent = addressEvent.replace(item, '');
        })
      }
      event = {
        description: `${profile[0].name}`,
        endTime: endDateTime,
        location: `${addressEvent}`,
        startTime: startDatetime,
        title: `${moreLang.title_service}`,
      }

    }

    return (

      <>
        <div className={this.props.eventId ? "wrapper page-ready" : "wrapper page-ready hidden"} >
          <header className="header">
            <div className="wrapper_in_block">
              <div className="header__wrapper">
                <div className="header__title">{t('ready__title')}</div>
                <div className="header__legend">
                  {t('ready__legend')}
                </div>
              </div>
            </div>
          </header>
          <section className="order">
            <div className="wrapper_in_block">
              <div className="order__info order__info_master">
                <div className="info__image"></div>
                <div className="info__label">
                  <div>{t('ready__master')}</div>
                  {this.props.profile[0].name}
                </div>
              </div>
              <div className="order__info order__info_master_location">
                <div className="info__image"></div>
                <div className="info__label">
                  {this.props.profile[0].address}
                </div>
              </div>
              <div className="order__info order__info_time">
                <div className="info__image"></div>
                <div className="info__label">
                  <div>
                    {t('calendar__dateTranslate', {
                      day: userDateOrder.day,
                      month: t('calendar__monthError', { returnObjects: true })[userDateOrder.month],
                      dayOfWeek: t('calendar__dayOfMonthSpec', { returnObjects: true })[new Date(userDateOrder.year, userDateOrder.month, userDateOrder.day).getDay()]
                    })}
                  </div>
                  <div>{api.durationEvent(userTimeOrderDateFormat, userDuration, this.props)}</div>
                </div>
              </div>
              <div className={api.setClasses(['order__info', 'order__info_cost', t('lang')])}>
                <div className="info__image"></div>
                <div className="info__label">
                  <div>{t('ready__price')}</div>
                  {api.renderPrice(
                    api.sumCalculate(this.props.serviceStore, this.props.serviceStore[0].currency),
                    this.props.serviceStore[0].currency,
                    this.state.resultSave[0]?.sale
                  )}
                </div>
              </div>

              {this.props.serviceStore.map((service, i) => {
                return (
                  <ReadySerivce
                    key={i}
                    t={t}
                    title_service={service.title_service}
                    cost_start=""
                    cost_finish={service.cost_finish}
                    price={service.price}
                    duration={service.duration}
                    currency={service.currency}
                  />
                )
              })}
              {/* <div className="info__deep">Предыдущие заказы</div> */}
              {/* {localStorage.getItem("fullOrder") ?                        // спросить у антохи по поводу классов
                this.state.arrOrder.map((service, i) => {
                  return (
                    <ReadySerivce
                      key={i}
                      t={t}
                      title_service={service[0].title_service}
                      cost_start={service[0].cost_start}
                      cost_finish={service[0].cost_finish}
                      duration={service[0].duration}
                      currency={service[0].currency}
                      orderItemData={service[1]}
                      orderItemTime={service[2]}
                      title_key={i}
                      userDuration={userDuration}
                    />
                  )
                }) : <></>
              } */}
            </div>
          </section>
          <footer className="footer">
            <div className="wrapper_in_block">
              <Button action={this.saveEventToCalendar} title={t('ready__saveTocalendar')} className="footer__btn" />
              <AddToCalendar
                listItems={[{ apple: 'Apple Calendar' }, { google: 'Google' }]}
                ref={this.icsItems}
                displayItemIcons={false}
                event={event}
              />
              <div onClick={this.newEvent} className="footer__controllers">{t('ready__createNextBtn')}</div>
              <div onClick={this.deleteEvent} className="footer__controllers">{t('ready__deleteNoteBtn')}</div>
            </div>
          </footer>
        </div>
        {!this.props.eventId &&
          <div className="preloader zindexHelp">
            <div className="fas fa-spinner fa-spin"></div>
          </div>
        }
      </>
    );
  }
}
const ReadyCls = withNamespaces()(Ready)
export default api.connect(ReadyCls);