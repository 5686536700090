import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import REDUX from './js/redux';
import App from './js/app.js';
import './scss/index.scss';
import './js/multilang/translate';

const STORE = createStore(
  REDUX.REDUCERS,
  REDUX.INITIALSTATE
);

ReactDOM.render(
  <Provider store={STORE}>
    <Router>
      <Route store={STORE} component={App} />
    </Router>
  </Provider>, document.getElementById('root'));
