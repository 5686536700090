import React from 'react';
import api from '../../api';
import Field from '../../common/field';
import HeaderMenager from '../headerMenager';
import SubError from '../../common/subErrors';
import Policy from "../../pages/policy";
import Modal from '@material-ui/core/Modal';

class ContactsDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNext: true,
      modalVisible: false
    }
  }
  isIsrael = () => {
    const { selectPhoneCode } = this.props;
    let condition = false;
    if (typeof selectPhoneCode === 'object') {
      if (selectPhoneCode.id === 4) {
        condition = true
      }
    }
    else {
      condition = false;
    }
    return condition;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onTermsClick = event => {
    event.preventDefault();
    this.setState({ modalVisible: true })
  }
  onApplyTerms = event => {
    event.preventDefault();
    this.setState({ modalVisible: false })
  }
  render() {
    const { t, selectPhoneCode } = this.props;
    const currentLength = selectPhoneCode ? api.maxLengthPhones.find((item) => item.code === selectPhoneCode.code).length : 14;
    const phoneValidator = this.props.userOrderPhone.length > currentLength - 1;
    const pureLength = selectPhoneCode ? api.maxLengthPhones.find((item) => item.code === selectPhoneCode.code).purelength : 10;
    const stringForError = `${t('contactDet__subError_phone_smallLength')} ${pureLength} ${t('isNumbers')}`;
    return (
      <>
        {this.state.modalVisible ?
          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open
            aria-labelledby="server-modal-title"
            aria-describedby="server-modal-description"
          >
            <div className="modalOffert" >
              <div>
                <Policy
                  t={this.props.t}
                  onApplyTerms={this.onApplyTerms}
                />
              </div>
            </div>
          </Modal>
        : <></>}
        {this.props.serviceStore.length &&
          <>
            <HeaderMenager
              idUrl={this.props.idUrl}
              step={this.props.step}
              t={t}
              currentPos={4}
              history={this.props.history}
              value={phoneValidator && this.props.userOrderName}
              serviceTitle={t('contactDet__title')}
              onTermsClick={this.onTermsClick}
            />
            <section className="section-usage phone">
              <div className="services__contacts">
                <div className="services__field">
                  <div className="wrapper_in_block">
                    <Field t={t} type={'name'} />
                  </div>
                  <div className="error_absolute">
                    <SubError text={t('contactDet__subError_name')}
                      expression={this.props.blockingNext && !this.props.userOrderName} />
                  </div>
                </div>


                {this.props.profile[0].required_instagram ? <div className="services__field">
                  <div className="wrapper_in_block">
                    <Field t={t} type={'insta'} />
                  </div>
                  <div className="error_absolute">
                    <SubError text={t('contactDet__subError_insta')}
                      expression={this.props.blockingNext && !this.props.userInstaName} />
                  </div>
                </div> : <></>
                }

                {this.props.profile[0].required_surname ?
                  <div className="services__field">
                    <div className="wrapper_in_block">
                      <Field t={t} type={'surname'} />
                    </div>
                    <div className="error_absolute">
                      <SubError text={t('contactDet__subError_surname')}
                        expression={this.props.blockingNext && !this.props.userSurnameName} />
                    </div>
                  </div> : <></>}

                <div className="services__field">
                  <div className="wrapper_in_block">
                    <Field t={t} type={'tel'} />
                  </div>
                </div>
                {this.props.blockingNext && this.props.userOrderName && !this.props.userOrderPhone &&
                  <SubError text={t('contactDet__subError_phone_empty')}
                    expression={this.props.blockingNext && this.props.userOrderName && !this.props.userOrderPhone} />
                }
                {this.props.blockingNext && this.props.userOrderName && this.props.userOrderPhone && this.props.userOrderPhone.length < currentLength &&
                  <SubError text={stringForError} expression={this.props.blockingNext && this.props.userOrderName && this.props.userOrderPhone
                    && this.props.userOrderPhone.length < currentLength} />
                }
              </div>
            </section>
          </>
        }
      </>
    )
  }
}
export default api.connect(ContactsDetails);