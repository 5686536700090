import React from 'react';
import api from '../api';
import doneIcon from '../../images/Check_small.svg';
import deleteField from '../../images/close.svg';
import { parsePhoneNumberFromString, AsYouType, getCountryCallingCode } from 'libphonenumber-js'

class Field extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusField: false,
      isLocal: false
    }
    this.entry = React.createRef();
    this.mask = React.createRef();
  }
  componentDidMount() {
    this.props.setRedux({
      selectPhoneCode: api.getCountryCode(this.props.phoneCode, this.props.userLocation)
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.editPhonePage) {
      if (prevProps.userOrderPhone !== this.props.userOrderPhone) {
        this.props.setRedux({
          wasChangePhone: true,
        });
      }
    }
  }
  changeField = (e) => {


    if (e.target.value.length > 0) {
      this.props.setRedux({
        blockingNext: false
      });
    }

    if (this.props.type === 'name') {
      this.props.setRedux({ userOrderName: e.target.value });
    }
    else if (this.props.type === "insta") {
      this.props.setRedux({ userInstaName: e.target.value });
    }
    else if (this.props.type === "surname") {
      this.props.setRedux({ userSurnameName: e.target.value });
    }
    else if (this.props.type === 'tel') {
      const phoneNumber = parsePhoneNumberFromString(e.target.value);

      if (phoneNumber) {
        const type = new AsYouType('US').input(phoneNumber.nationalNumber);
        this.props.setRedux({
          userOrderPhone: type
        });

      }
      else {
        const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,1})/);
        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] + x[4] : '');
        this.props.setRedux({ userOrderPhone: e.target.value });
      }
    }
  }
  focus = (e) => {
    this.setState({
      focusField: true
    });
    if (e.target.type === 'text') {
      this.props.setRedux({
        blockingNext: false
      });
    }
    e.target.placeholder = ' ';
  }
  clearField = () => {
    if (this.props.type === 'name') {
      this.props.setRedux({
        userOrderName: ''
      });
    }
    else if (this.props.type === 'tel') {
      this.props.setRedux({
        userOrderPhone: ''
      });
    }
    else if (this.props.type === 'surname') {
      this.props.setRedux({
        userSurnameName: ''
      });
    }

    else if (this.props.type === 'insta') {
      this.props.setRedux({
        userInstaName: ''
      })
    }
  }
  notCorrectLength = (phone) => {
    const { selectPhoneCode } = this.props;
    const parsePhone = phone.replace(/\D/gm, '');

    let condition = false;
    if (typeof selectPhoneCode === 'object') {
      if (selectPhoneCode.id === 4) {
        condition = parsePhone.length < 9
      }
      else {
        condition = parsePhone.length < 10
      }
    }
    else {
      condition = parsePhone.length < 10
    }
    return condition;
  }
  blur = (e) => {
    const { userOrderPhone } = this.props;
    this.props.setRedux({
      blockingNext: false
    });
    if (e.relatedTarget) {
      if (e.relatedTarget.classList.contains('field_remove_btn')) {
        e.preventDefault();
      }
      else {
        this.setState({
          focusField: false
        });
      }
      if (e.relatedTarget.classList.contains('field__input') && this.notCorrectLength(userOrderPhone)) {
        this.props.setRedux({
          blockingNext: true
        });
      }
    }
    else {
      this.setState({
        focusField: false
      });
    }
    e.target.placeholder=this.props.t(`input__${e.target.name}_placeholder`)

  }
  renderNameField = () => {
    const { t } = this.props;
    return (
      <div className="field__flex">
        <div className={api.setClasses(['field__icon'], { activeFieldName: this.props.userOrderName })}></div>
        <div className="field__wrapper">
          <label>{t('input__name_label')}</label>
          <input
            ref={this.entry}
            onBlur={this.blur}
            autoComplete="name"
            name="name"
            id="name"
            value={this.props.userOrderName}
            onFocus={this.focus}
            onChange={this.changeField}
            autoFocus
            maxLength="50"
            className="field__input"
            placeholder={t('input__name_placeholder')}
            type="text" />
        </div>
        {this.state.focusField && this.props.userOrderName &&
          <div tabIndex="0" className="field_remove_btn">
            <img onClick={this.clearField} className="field__controller" src={deleteField} alt="Delete" />
          </div>
        }
        {!this.state.focusField && this.props.userOrderName &&
          <img className="field__controller" src={doneIcon} alt="complete" />
        }
      </div>
    )
  }

  renderInstaField = () => {
    const { t } = this.props;
    return (
      <div className="field__flex">
        <div className={api.setClasses(['field__icon'], { activeFieldName: this.props.userInstaName })}></div>
        <div className="field__wrapper">
          <label>instagram</label>
          <input
            ref={this.entry}
            onBlur={this.blur}
            autoComplete="insta"
            name="insta"
            id="insta"
            onTouchMove={this.touching}
            value={this.props.userInstaName}
            onFocus={this.focus}
            onChange={this.changeField}
            maxLength="50"
            className="field__input"
            placeholder={t('input__insta_placeholder')}
            type="text" />
        </div>
        {this.state.focusField && this.props.userInstaName &&
          <div tabIndex="0" className="field_remove_btn">
            <img onClick={this.clearField} className="field__controller" src={deleteField} alt="Delete" />
          </div>
        }
        {!this.state.focusField && this.props.userInstaName &&
          <img className="field__controller" src={doneIcon} alt="complete" />
        }
      </div>
    )
  }

  renderSurnameField = () => {
    const { t } = this.props;
    return (
      <div className="field__flex">
        <div className={api.setClasses(['field__icon'], { activeFieldName: this.props.userSurnameName })}></div>
        <div className="field__wrapper">
        <label>{t('input__surname_label')}</label>
          <input
            ref={this.entry}
            onBlur={this.blur}
            autoComplete="surname"
            name="surname"
            id="surname"
            onTouchMove={this.touching}
            value={this.props.userSurnameName}
            onFocus={this.focus}
            onChange={this.changeField}
            maxLength="50"
            className="field__input"
            placeholder={t("input__surname_placeholder")}
            type="text" />
        </div>
        {this.state.focusField && this.props.userSurnameName &&
          <div tabIndex="0" className="field_remove_btn">
            <img onClick={this.clearField} className="field__controller" src={deleteField} alt="Delete" />
          </div>
        }
        {!this.state.focusField && this.props.userSurnameName &&
          <img className="field__controller" src={doneIcon} alt="complete" />
        }
      </div>
    )
  }


  selectCountry = (e) => {
    let finder = this.props.phoneCode;
    this.props.setRedux({
      userOrderPhone: '',
      blockingNext: false
    });
    finder.forEach((item) => {
      if (item.country.toUpperCase() === e.target.value.toUpperCase()) {
        this.props.setRedux({
          selectPhoneCode: item
        });
      }
    })
  }
  pastePhone = (e) => {


    e.preventDefault();
    const text = (e.originalEvent || e).clipboardData.getData('text/plain');
    const phoneNumber = parsePhoneNumberFromString(text);
    if (phoneNumber) {

      const country = phoneNumber.country;

      const type = new AsYouType('US').input(phoneNumber.nationalNumber);
      if (phoneNumber.isValid()) {
        this.props.setRedux({
          userOrderPhone: type
        });
      }
      const currentCntCode = getCountryCallingCode(country);
      this.props.phoneCode.forEach((option) => {
        if (parseInt(currentCntCode) === option.code) {

          this.props.setRedux({
            selectPhoneCode: option
          });
        }
      });
    }
  }
  renderPhoneField = () => {
    const { selectPhoneCode, t } = this.props;
    if (!selectPhoneCode) return false

    return (
      <div className="field__flex">
        <div className={api.setClasses(['field__icon_tel'], { activeFielTel: this.props.userOrderPhone.match(/[0-9]/) !== null })}></div>
        <div className="field__wrapper phone__wrapper">
          <div className="phone__select">
            {typeof this.props.selectPhoneCode === 'object' ?
              (
                <div className="select__current">{this.props.phoneCode.map((option, i) => {
                  if (option.country === this.props.selectPhoneCode.country) {
                    return (
                      <span key={i}><span className="country">{option.country}</span> +{option.code}</span>
                    )
                  }
                  return false;
                })}
                </div>
              )
              :
              (
                <div className="select__current">
                  <span><span className="country">{'Россия'}</span> {'+ 7'}</span>
                </div>
              )
            }
            <select defaultValue={this.props.selectPhoneCode.country} onChange={this.selectCountry} className="field__select">
              {this.props.phoneCode.map((option, i) => {
                if (option.country === this.props.selectPhoneCode.country) {
                  return (
                    <option key={i} value={option.country} selected="selected">{option.country} +{option.code}</option>
                  )
                }
                else {
                  return (
                    <option value={option.country} key={i}>{option.country} +{option.code}</option>
                  )
                }
              })}
            </select>
          </div>
          <label htmlFor="tel"></label>
          <input
            ref={this.mask}
            onBlur={this.blur}
            value={this.props.userOrderPhone}
            onFocus={this.focus}
            onChange={this.changeField}
            name="tel"
            id="tel"
            onTouchMove={this.touching}
            autoComplete="tel"
            maxLength={selectPhoneCode ? api.maxLengthPhones.find((item) => item.code === selectPhoneCode.code).length : 14}
            onPaste={this.pastePhone}
            placeholder={t("input__tel_placeholder")}
            className="field__input_tel"
            type="tel" />
        </div>
        {
          this.state.focusField && this.props.userOrderPhone &&
          <div tabIndex="0" className="field_remove_btn">
            <img onClick={this.clearField} className="field__controller" src={deleteField} alt="Delete" />
          </div>
        }
        {this.renderMarkCheckForField(selectPhoneCode) &&
          <img className="field__controller" src={doneIcon} alt="С" />
        }
      </div >
    )
  }

  renderMarkCheckForField = (phoneCode) => {
    const lengtPhoneInfo = api.maxLengthPhones.find((item) => item.code === phoneCode.code);
    const purelength = lengtPhoneInfo.purelength;
    const orderPhone = this.props.userOrderPhone.replace(/\D/gm, '');

    let isRender = false

    if (lengtPhoneInfo && purelength && !this.state.focusField && (orderPhone.length === purelength)) {
      isRender = true;
    }

    return isRender;
  }


  render() {
    if (this.props.type === 'name') {
      return (
        this.renderNameField()
      )
    }
    else if (this.props.type === 'tel') {
      return (
        this.renderPhoneField()
      )
    }
    else if (this.props.type === "surname") {
      return (
        this.renderSurnameField()
      )
    }
    else if (this.props.type === "insta") {
      return (
        this.renderInstaField()
      )
    }
  }
}
export default api.connect(Field);