import React from 'react';
import api from '../api';
import { addWeeks, addDays, differenceInCalendarDays, getDay, differenceInDays } from 'date-fns';
class TableCeil extends React.Component {
  constructor(props) {
    super(props)
    this.ceil = React.createRef();
  }
  getClass = () => {
    let calculateTime = api.timeCalculate(this.props.serviceStore).h * 60 + api.timeCalculate(this.props.serviceStore).min;
    const {
      day,
      isApi,
      selectMonth,
      selectYear,
      helpertListMonth,
    } = this.props

    // switchClass();
    const monthShouldNotPrev = () => {
      if (selectMonth >= new Date().getMonth() && selectYear === new Date().getFullYear()) {
        return true;
      }
      else if (selectYear > new Date().getFullYear()) {
        return true
      }
      else {
        return false
      }
    }
    const currentMonth = selectYear === new Date().getFullYear() && selectMonth === new Date().getMonth();
    let dat;
    let today;
    let dateInterval;
    let endPoint;
    const prevDays = () => {
      if (day.item < new Date().getDate() && currentMonth) {
        return true;
      }
      return false;
    }
    if (this.props.profile[0].fixed_date) {
      dat = new Date(this.props.profile[0].fixed_date);
      dat.setDate(dat.getDate() + 1);
      dateInterval = differenceInDays(dat, new Date())
      today = new Date() 
      endPoint = addDays(today, dateInterval);
    } else {
      today = new Date()
      endPoint = addWeeks(today, this.props.profile[0].max_date_booking);
    }
    let maxDateForBoking;
    if (endPoint.getMonth() === selectMonth) {

      let maxDate;
      let diffDays = differenceInCalendarDays(today, endPoint);
      diffDays < 0 ? diffDays = - diffDays : diffDays = diffDays;
      for (let i = 1; i <= diffDays; i++) {
        let dt = addDays(today, i);
        if (i === diffDays) {
          maxDate = dt;
        }
      }
      maxDateForBoking = maxDate.getDate();

    }
    const switcherClassForDay = (timeJob = day.timeJobApi ? day.timeJobApi : day.timeJob) => {
      let hasTimeJob = timeJob ? timeJob.time_interval >= calculateTime : false,
        isToday = (selectYear === new Date().getFullYear() && selectMonth === new Date().getMonth()
          && day.item === new Date().getDate());
      if (endPoint.getMonth() === selectMonth) {
        if (day.item > maxDateForBoking) {
          hasTimeJob = 0;
        }
      }
      let returnClass = [hasTimeJob && !isToday && !prevDays() ? 'table__ceil work' : false,
      hasTimeJob && !isToday && prevDays() ? 'table__ceil close__date' : false,
      hasTimeJob && isToday ? 'table__ceil work td' : false,
      !hasTimeJob && !isToday ? 'table__ceil close__date' : false,
      !hasTimeJob && isToday ? 'table__ceil close__date td' : false,
      ];

      return returnClass.find((currentClass) => currentClass !== false);
    }
    return switcherClassForDay();
  }
  render() {
    return (
      <div className="table__custom">
        {this.props.day.active &&
          <div ref={this.ceil} onClick={(e) => this.props.selectDay(e, this.props.day.id, this.props.day)} className={this.getClass()}>{this.props.day.item}</div>
        }
        {!this.props.day.active &&
          <div className="table__ceil disable">{this.props.day.item}</div>
        }
      </div>
    )
  }
}
export default api.connect(TableCeil);
