import React from 'react';
import logo from '../../../images/logo.svg';
import appStore from '../../../images/appStore.svg';
import appStore_en from '../../../images/appStore_en.svg';
const logos = {
  ru: appStore,
  en: appStore_en
}
const { CONFIG } = window;

export default class WelcomeHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isIOS: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    }
  }
  render() {
    const { t } = this.props
    return (
      <header className="header-welcome">
        <div className="wrapper_in_block">
          <div className="welcome__bar">
            <img className="welcome__logo" src={logo} alt="logo" />
            {this.state.isIOS &&
              <a className="welcome__download__link" target="_blank" href={CONFIG.appUrl[t('lang')]}>
                <img className="welcome__download__logo" src={logos[t('lang')]} alt="download app" />
              </a>
            }
          </div>
        </div>
      </header>
    )
  }
}