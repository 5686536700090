
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import api, { getCookie } from '../api';
import { format } from 'date-fns';

class ModalDelete extends Component {
  closeModal = () => {
    this.props.setRedux({
      showModal: false
    });
  }
  deleteEvent = async () => {
    const {
      userTimeOrder,
      profile
    } = this.props;
    let bodyReq = { "id_e": this.props.eventId };
    this.props.setRedux({
      showModal: false,
      isPreloaderActive: true
    });
    const profileMaster = profile[0]
    let date = new Date(`${this.props.userDateOrder.year}/${this.props.userDateOrder.month + 1}/${this.props.userDateOrder.day}`)
    let sendDate = format(date, 'dd.MM.yyyy')
    await api.notifyMaster(
      {
        isSave: false,
        profile: profileMaster,
        serviceName: this.props.serviceStore.reduce(function (a, b) { return a.title_service.length > b.title_service.length ? a : b; }),
        type: 'Create event',
        user: {
          timeOrder: userTimeOrder,
          date: `${sendDate}`
        }
      }
    );
    api.deleteEvent(bodyReq).then(() => {
      let masterId = this.props.profile[0].id;
      if (this.props.firstStep === 'list') {
        masterId = this.props.masterList[0].id_master;
      }

      if (getCookie('carabine')) {
        this.fetchData(getCookie, masterId);
        this.props.history.push(api.prepareUrl(`/master/${masterId}/${this.props.firstStep}`))
      }
      else {
        this.props.history.push(api.prepareUrl(`/master/${masterId}`))
      }
    });
  }

  fetchData = (getCookie, masterId) => {
    const clearData = {
      serviceStore: [],
      masterList: [],
      profile: [],
      availableMasterDays: [],
      userHaveAuth: getCookie('carabine') ? true : false,
      userOrderName: getCookie('carabine') ? this.props.userOrderName : '',
      userOrderPhone: getCookie('carabine') ? this.props.userOrderPhone : '',
      verifyCodeA: '',
      verifyCodeB: '',
      verifyCodeC: '',
      verifyCodeD: '',
      userTimeOrder: '',
      userDateOrder: '',
      userVerify: getCookie('carabine') ? this.props.userVerify : false,
    }
    this.props.setRedux(clearData);

    if (this.props.firstStep === 'list') {
      this.setDependeces();
      this.getSaloonData(masterId);
      return;
    }

    if (this.props.firstStep === 'services') {
      this.setDependeces();
      this.getProfileData(masterId, Object.assign({}, this.props, clearData));
      return;
    }
  }

  setDependeces = () => {
    this.props.setRedux({
      isPreloaderActive: true,
    });
    document.body.classList.add('stop__scroll');
  }

  getSaloonData = async (masterId) => {
    const res = await api.fetchSaloonData(masterId);
    this.props.setRedux({
      masterList: res,
    });
    setTimeout(() => {
      this.props.setRedux({
        isPreloaderActive: false,
      });
    })
    document.body.classList.remove('stop__scroll');
  }

  getProfileData = async (masterId, newProps) => {
    const resultData = await api.fetchData(masterId);
    if (resultData.length) {
      api.checkMasterDataIsFull(resultData, this.props.setRedux, newProps);
      resultData.map((item, i) => {
        item['_id'] = i;
        item['duration'] = api.translateTime(item.duration);
        if (this.props.firstStep === 'list') {
          item['address'] = this.props.masterList[0].address;
        }
        return item;
      });
      this.props.setRedux({ profile: resultData, });

      const result = await Promise.all([
        api.fetchAvailableDays({
          id: resultData[0].id,
          masterWork: api.parserTimeJob(resultData[0].time_job),
          month: this.props.selectMonth + 1,
          year: this.props.selectYear
        }),
        api.fetchLocation(),
        api.fetchCodePhone()
      ]);

      result[0].forEach((day) => {
        /* eslint-disable */
        day.edate = day.edate.match(/(?:\-)(\d\d)/gm)[1].replace(/-/, '')
        /* eslint-enable */
      });
      this.props.setRedux({ availableMasterDays: result[0] });

      if (result[1]) {
        this.props.setRedux({ userLocation: result[1] });
      }

      if (result[2]) {
        this.props.setRedux({ phoneCode: result[2] });
      }
      setTimeout(() => {
        this.props.setRedux({
          isPreloaderActive: false,
        });
      })
    } else {
      this.props.isEnglish ? this.props.history.push("/en/404") :  this.props.history.push("/404");
    }
    document.body.classList.remove('stop__scroll');
  }
  render() {
    const { t } = this.props;
    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
      >
        <div className="wrapper__modal">
          <div className="modal__wrapper">
            <h2 className="modal__title">{t('modal__delete__title')}</h2>
            <span className="modal__description">
              {t('modal__delete__description')}
            </span>
            <div className="modal__btns">
              <div onClick={this.closeModal} className="modal__btn">{t('modal__delete__cancel')}</div>
              <div onClick={this.deleteEvent} className="modal__btn">{t('modal__delete__delete')}</div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
const ModalDeleteCls = withNamespaces()(ModalDelete)
export default api.connect(ModalDeleteCls);