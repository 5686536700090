import api from "../api";
import WelcomeHeader from "../components/welcome/welcomeHeader";
import GoogleReCaptchaComponent from '../components/captcha';
import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Preload from "../components/preload";
import ReCaptchaV2 from "./captcha";
const { CONFIG } = window;

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: [],
      activeCaptcha: false,
      count: 0,
      captchaV2: false
    };
  }
  makeCaptching = () => {
    this.setState({
      activeCaptcha: true,
    });
  };
  componentDidMount() {
    if (this.props.userHaveAuth) {
      const redirectUrl = api.prepareUrl(`/master/${this.props.match.params.id}`)
      this.props.history.push('')
      console.error('todo')
    }
    this.props.setRedux({
      showModal: false,
      showError: false,
    });
    const { readyActive, userOrderName, userOrderPhone } = this.props;

    if (api.getCookie("carabine")) {
      this.props.history.push(api.prepareUrl(`/master/${this.props.match.params.id}/list`));
    }
    if (readyActive) {
      this.props.setRedux({
        serviceStore: [],
        userHaveAuth: api.getCookie("carabine") ? true : false,
        userOrderName: api.getCookie("carabine") ? userOrderName : "",
        userOrderPhone: api.getCookie("carabine") ? userOrderPhone : "",
        verifyCodeA: "",
        verifyCodeB: "",
        verifyCodeC: "",
        verifyCodeD: "",
        userTimeOrder: "",
        userDateOrder: "",
      });
    }

    let urlArr = window.location.pathname.split('/');
      if (urlArr.includes('en')) {
        urlArr.splice(1,1);
        this.props.setRedux({
          isEnglish: true,
          startingUrl: urlArr.join('/'),
        })
      } else {
        this.props.setRedux({
          isEnglish: false,
          startingUrl: window.location.pathname,
        })
      }

    window.scrollTo(0, 0);
  }

  verify = (token, v3 = false) => {                              // true - значит будет идти запрос на бэк - капча в2
    if (token) {
      this.props.setRedux({
        isPreloaderActive: true,
      });

      api.fetchingToken(token, v3).then((res) => {
        this.props.setRedux({
          isPreloaderActive: false,
        });
        if (res.success) {
          this.props.setRedux({
            userVerify: true,
            serviceStore: [],
            isPreloaderActive: true,
          });
          api.fetchSaloonData(this.props.match.params.id).then(async (res) => {
            if (res.length) {
              this.props.setRedux({
                isPreloaderActive: false,
                masterList: res,
              });
              window.location.reload()
            }
            else {
              await api.fetchData(this.props.match.params.id).then(async (res) => {
                if (res.length) {
                  api.checkMasterDataIsFull(res, this.props.setRedux, this.props);
                  res.map((item, i) => {
                    item["_id"] = i;
                    item["duration"] = api.translateTime(item.duration);
                    return item;
                  });

                  this.props.setRedux({ profile: res, firstStep: 'list' });

                  await api.fetchAvailableDays({
                    id: this.props.profile[0].id,
                    masterWork: api.parserTimeJob(this.props.profile[0].time_job),
                    month: this.props.selectMonth + 1,
                    year: this.props.selectYear,
                  })
                    .then((res) => {
                      res.forEach((day) => {
                        day.edate = day.edate
                          .match(/(?:\-)(\d\d)/gm)[1]
                          .replace(/-/, "");
                      });
                      let indexA, indexB;
                      this.props.setRedux({
                        availableMasterDays: res,
                      });
                    });

                  await api.fetchLocation()
                    .then((res) => {
                      if (res) {
                        this.props.setRedux({
                          userLocation: res,
                        });
                      }
                      if (this.state.isLoad.length === 3) {
                        this.props.setRedux({
                          isPreloaderActive: false,
                        });
                      }
                    });

                  await api.fetchCodePhone()
                    .then((res) => {
                      if (res) {
                        this.props.setRedux({
                          phoneCode: res,
                        });
                        window.location.reload()
                      }
                    });
                } else {
                  this.props.isEnglish ? this.props.history.push("/en/404") :  this.props.history.push("/404");
                }
              });
            }
          });
        }

        else {
          if (!this.state.captchaV2) {
            this.onVerifyError()
          }
          return false;
        }
      });
    }
  }

  onVerifyError = () => {
    this.setState({ captchaV2: true });
    // this.setState({ userCaptchaV2: true });
    //this.props.history.push(`/master/${this.props.match.params.id}/captchaV3`)
  }

  render() {
    const { t } = this.props;
    if (this.state.captchaV2) {
      return <ReCaptchaV2 t={t} onVerify={this.verify} />
    }
    return (
      <>
        <Preload />
        <div className="preloaderFonts">
          <p className="bold__font">font loading...</p>
          <p className="regula__font">font loading...</p>
          <p className="thin__font">font loading...</p>
          <p className="light__font">font loading...</p>
          <p className="ghotic__font">font loading...</p>
          <p className="averin__font">font loading...</p>
        </div>
        <WelcomeHeader t={t} />
        <section className="welcome-section">
          <div className="wrapper_in_block section__wrapper">
            <div className="section__title">{t("welcome__title")}</div>
            <div>
              <GoogleReCaptchaComponent onVerify={this.verify} onError={this.onVerifyError} t={t} />
            </div>
          </div>
        </section>
      </>
    );
  }
}
const WelcomeClass = withNamespaces()(Welcome);
export default api.connect(WelcomeClass);
