import React from 'react';
import api, { getCookie } from '../../api';
import MasterItem from '../masterItem';
import HeaderMenager from '../headerMenager';

class MasterList extends React.Component {

  componentDidUpdate() {
    if (!getCookie('carabine')) {
      this.props.setRedux({
        masterList: [],
        profile: [],
        userHaveAuth: false,
        userOrderName: '',
        userOrderPhone: '',
        verifyCodeA: '',
        verifyCodeB: '',
        verifyCodeC: '',
        verifyCodeD: '',
        userTimeOrder: '',
        userDateOrder: '',
        userVerify: false
      });
      this.props.history.push(api.prepareUrl(`/master/${this.props.dataList[0].id}`));
    }
  }

  render() {
    const {
      t,
    } = this.props;
    const headerInfo = {
      name: this.props.dataList.length ? this.props.dataList[0].title_salon : t('unknown'),
      address: this.props.dataList.length ? this.props.dataList[0].address : t('unknown'),
      title: t('masterList__title')
    }

    return (
      <>
        {!!this.props.masterList.length &&
          <>
            <HeaderMenager
              idUrl={this.props.idUrl}
              step={this.props.step}
              t={t}
              currentPos={1}
              history={this.props.history}
              headerInfo={headerInfo}
              serviceTitle={t('masterList__mainTitle')}
            />
            <section className="section-usage no_margin">
              <div className="service__list">
                {this.props.dataList.map((item, i) => (
                  <MasterItem
                    history={this.props.history}
                    fio={item.fio}
                    id_master={item.id}
                    goBack={this.forwardBack}
                    data={item}
                    t={this.props.t}
                    key={i} />
                ))}
              </div>
            </section>
          </>
        }
      </>
    );
  }
}

export default api.connect(MasterList);