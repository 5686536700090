import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import WelcomeHeader from '../components/welcome/welcomeHeader';
import api from '../api';

class NotFound extends Component {
  componentDidMount() {
    this.props.setRedux({
      isPreloaderActive: false
    });
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <WelcomeHeader t={t} />
        <section className="notfound-section">
          <div className="wrapper_in_block notfound-wrapper">
            <div className="notfound__width">
              <div className="section__title">
                {t('notFound__title')}
              </div>
              <div className="section__info">
                <div className="info__preview">{t('notFound__sub_title')}</div>
                <ul className="info__list">
                  <li className="info__list__item">
                    <span></span>
                    <div>
                      {t('notFound__reason_first')}
                    </div>
                  </li>
                  <li className="info__list__item">
                    <span></span>
                    <div>
                      {t('notFound__reason_second')}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
const NotFoundCls = withNamespaces()(NotFound);
export default api.connect(NotFoundCls);