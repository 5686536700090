import React from 'react';
import api from '../api';
class ReadySerivce extends React.Component {
  render() {
    const { t } = this.props;
    let { price } = this.props;
    if (price.split('-')[1]) {
      price = price.split('-')[1].trim()
    }

    let time = '';
    if (this.props.duration.h !== 0) {
      time += `${this.props.duration.h} ${api.wordType(this.props.duration.h, [t('hoursIn_header')
      , t('hoursIn_header_2'), t('hoursIn_header_3')])} `
    }

    if (this.props.duration.min !== 0) {
      time += `${this.props.duration.min} ${t('global__minutes')}`
    }
    return (
      <div className="order__info order__info_service">
        <div className="info__image"></div>
        <div className="info__label">
          <div>
            {this.props.orderItemData}
          </div>
          <div>
            {this.props.orderItemTime}
          </div>
          <div>{this.props.title_service}</div>
          <div className="info__deep">
            {price} 
            <span></span>
            {time}
          </div>
        </div>
      </div>
    )
  }
}
export default api.connect(ReadySerivce);