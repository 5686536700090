export default {
  isPreloaderActive: false,
  isConfirmTermOfuse: false,
  blockingNext: false,
  userLanguage: navigator.language || navigator.userLanguage,
  userStep: 1,
  firstStep: 'list', 
  userVerify: false,
  selectMonth: new Date().getMonth(),
  selectYear: new Date().getFullYear(),
  datePicker: "",
  serviceStore: [],
  masterList: [],
  profile: "",
  userTimeOrder: "",
  userDateOrder: "",
  needMobileVerify: false,
  userHaveAuth: false,
  masterFreeTime: {},
  userOrderName: "",
  userOrderPhone: "",
  userLocation: {},
  verifCodeRender: false,
  userVerification: true,
  timer: 60,
  saveServices: [],
  verifyPage: false,
  selectPhoneCode: "",
  phoneCode: {},
  verifyCodeA: "",
  verifyCodeB: "",
  verifyCodeC: "",
  verifyCodeD: "",
  againSend: false,
  helpView: false,
  editPhonePage: false,
  serviceDeleteTrigger: false,
  wasChangePhone: false,
  api: [
    {
      id: 0,
      titleService: "Окрашивание ",
      time: {
        h: 1,
        min: 30,
      },
      price: [1000, 2500],
    },
    {
      id: 1,
      titleService: "Балаяжжжжжжжжжжжжжжжжжжжжжжжжжжжжжжжжжжжжжж",
      time: {
        h: 1,
        min: 30,
      },
      price: [1000, 2500],
    },
    {
      id: 2,
      titleService: "Маникюр",
      time: {
        h: 1,
        min: 30,
      },
      price: [1000, 2500],
    },
    {
      id: 3,
      titleService: "Маникюр",
      time: {
        h: 1,
        min: 30,
      },
      price: [1000, 2500],
    },
  ],
  errorMessages: ["Услуги", "Даты"],
  masterDataIsFull: false,
  isTimeInterval: false,
  startingUrl: false,
  isEnglish: false,
};
