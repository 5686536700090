import React from 'react';
import api, { getCookie } from '../../api';
import ServiceItem from '../usageServiceList/serviceItem';
import HeaderMenager from '../headerMenager';
import Preload from '../preload';
class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNext: true
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentDidUpdate() {
    if (!getCookie('carabine')) {
      this.props.setRedux({
        serviceStore: [],
        userHaveAuth: false,
        userOrderName: '',
        userOrderPhone: '',
        verifyCodeA: '',
        verifyCodeB: '',
        verifyCodeC: '',
        verifyCodeD: '',
        userTimeOrder: '',
        userDateOrder: '',
        userVerify: false
      });
      this.props.history.push(api.prepareUrl(`/master/${this.props.dataList[0].id}`));
    }
  }

  render() {
    const { t } = this.props;
    const headerInfo = {
      name: this.props.dataList.length ? this.props.dataList[0].name : t('unknown'),
      address: this.props.dataList.length ? this.props.dataList[0].address : t('unknown'),
      title: t('wizard__title')
    }
    return (
      <>
        {!!this.props.dataList.length &&
          <>
            <HeaderMenager
              idUrl={this.props.idUrl}
              step={this.props.step}
              t={t}
              currentPos={1}
              history={this.props.history}
              headerInfo={headerInfo}
              value={this.props.serviceStore.length}
              serviceTitle={t('wizard__mainTitle')}
              isNextComp={this.state.isNext}
            /> 
            <section className="section-usage no_margin">
              {!!this.props.dataList.length &&
                <div className="service__list">
                  {this.props.dataList.map((item, i) => (
                    <ServiceItem
                      title={item.title_service}
                      id={item.id}
                      _id={item._id}
                      goBack={this.forwardBack}
                      data={item}
                      t={this.props.t}
                      time={item.duration}
                      price={{
                        /* eslint-disable */
                        start: item.cost_start ? item.cost_start.replace(/[^(\d|\.)]/g, '') : '',
                        finish: item.cost_finish ? item.cost_finish.replace(/[^(\d|\.)]/g, '') : ''
                        /* eslint-enable */
                      }}
                      key={i} />
                  ))}
                </div>
              }
            </section>
          </>
        }
        {!this.props.dataList.length &&
          <>
            <Preload />
            <div className="preloaderFonts none">
              <p className="bold__font">font loading...</p>
              <p className="regula__font">font loading...</p>
              <p className="thin__font">font loading...</p>
              <p className="light__font">font loading...</p>
              <p className="ghotic__font">font loading...</p>
              <p className="averin__font">font loading...</p>
            </div>
          </>
        }
      </>
    )
  }

}
export default api.connect(Services);