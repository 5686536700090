import Main from './main';
import Usage from './usage';
import Welcome from './welcome';
import Exact from './exact';
import NotFound from './notFound';
import Ready from './ready';
import Policy from './policy';
export default {
    Main,
    Usage,
    Welcome,
    Policy,
    Exact,
    NotFound,
    Ready,
}