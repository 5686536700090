import React from 'react';

export default class Button extends React.Component {
  render() {
    return (
      <div onClick={this.props.action} className={this.props.className}>
        <div>{this.props.title}</div>
      </div>
    );
  }
}