import React from 'react';
import api from '../api';

export class Preloader extends React.Component {
  render() {
    return (
      <div className="preloader">
        <div className="fas fa-spinner fa-spin"></div>
      </div>
    );
  }
}

export default api.connect(Preloader);