import React from 'react';
import api from '../api';
export default function Error({ expression, paramsUrl, t }) {
  return (
    <div className={api.setClasses(['block__error'], { active__error: expression })}>
      <div className="error__message">
        <div className="wrapper_in_block">
          <span>{t('choose')} {api.getError(paramsUrl, t('list__errors', { returnObjects: true }))}</span>
        </div>
      </div>
    </div>
  );
}