import React from 'react';
import api, { getCookie } from '../api';
import { Link } from 'react-router-dom';

export class MasterItem extends React.Component {

  chooseMaster = async () => {
    if (getCookie('carabine')) {
      this.props.setRedux({
        isPreloaderActive: true,
      });
      const clearData = {
        serviceStore: []
      };
      this.props.setRedux(clearData);
      await this.getProfileData(Object.assign({}, this.props, clearData));
    }
  }

  getProfileData = async (props) => {
    const resultData = await api.fetchData(this.props.data.id_master);
    if (resultData.length) {
      api.checkMasterDataIsFull(resultData, this.props.setRedux, props);
      resultData.map((item, i) => {
        item['_id'] = i;
        item['duration'] = api.translateTime(item.duration);
        item['address'] = this.props.masterList[0].address;
        return item;
      });
      this.props.setRedux({ profile: resultData });

      const result = await Promise.all([
        api.fetchAvailableDays({
          id: resultData[0].id,
          masterWork: api.parserTimeJob(resultData[0].time_job),
          month: this.props.selectMonth + 1,
          year: this.props.selectYear
        }),
        api.fetchLocation(),
        api.fetchCodePhone()
      ]);

      result[0].forEach((day) => {
        /* eslint-disable */
        day.edate = day.edate.match(/(?:\-)(\d\d)/gm)[1].replace(/-/, '')
        /* eslint-enable */
      });
      this.props.setRedux({ availableMasterDays: result[0] });

      if (result[1]) {
        this.props.setRedux({ userLocation: result[1] });
      }

      if (result[2]) {
        this.props.setRedux({ phoneCode: result[2] });
      }
      setTimeout(() => {
        this.props.setRedux({
          isPreloaderActive: false,
        });
      })
    } else {
      this.props.isEnglish ? this.props.history.push("/en/404") :  this.props.history.push("/404");
    }
  }

  render() {
    const link = api.prepareUrl(`/master/${this.props.data.id_master}/services`);

    return (
      <Link onClick={this.chooseMaster} className="product master__item" to={link}>
        <div className="wrapper_in_block master__flex">
          <div className="master__dashboard">
            <div className="master__avatar"></div>
            <div className="product__dashboard">
              <h2 className="dashboard__title">{this.props.data.fio}</h2>
            </div>
          </div>
          <div className="master__next-step"></div>
        </div>
      </Link>
    )
  }
}

export default api.connect(MasterItem);