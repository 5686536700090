import React from 'react';
import api from '../../api';
import HeaderMenager from '../headerMenager';
import CalendarComp from '../calendar';

class DateOrder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNext: true
    }
  }
  setDate = () => {
    this.props.setRedux({
      datePicker: true
    })
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.serviceStore.length &&
          <HeaderMenager
            t={t}
            idUrl={this.props.idUrl}
            step={this.props.step}
            currentPos={2}
            history={this.props.history}
            isNextComp={this.state.isNext}
            serviceTitle={t('dateOrder__title')}
            value={this.props.userDateOrder}
          />
        }
        <section className="section-usage">
          <div onClick={this.setDate} className="services__date">
            <CalendarComp 
              t={t}
            />
          </div>
        </section>
      </>
    )
  }
}
export default api.connect(DateOrder);